import { createContext, useContext } from 'react';

import useLocalStorage from 'hooks/useLocalStorage';
import useSessionStorage from 'hooks/useSessionStorage';

import axios, { AxiosResponse } from 'axios';
import { axiosInstance } from 'lib/axios';

import Alunoservices from 'services/api/AlunosService';
import { EscolasServices } from 'services/api/EscolasService';
import { InstrutoresServices } from 'services/api/InstrutorService';
import SecretManagerKeyServices from 'services/api/SecretManagerKeyServices';
import UserServices from 'services/api/UserService';
import BigQueryAPI from 'services/external/BigQueryAPI';
import { PlurallProfileFromAcessTokenResponse } from 'services/external/PlurallAPI';
import LocalStorage from 'util/local-storage';
import SessionStorage from 'util/session-storage';

import { CHATBOT_CONTEXT_LOCAL_STORAGE_KEY } from 'contexts/ChatbotContextProvider';

type Only<T, U> = {
  [P in keyof T]: T[P];
} & {
  [P in keyof U]?: never;
};

type Either<T, U> = Only<T, U> | Only<U, T>;

export type EitherMMCodeOrSelfie = Either<{ isMMCode: boolean }, { isSelfie: boolean }>;
export type EitherReplaceOrUpdateIfFalsy = Either<{ replace: true }, { updateIfFalsy: true }>;

type UserType = 'admin' | 'assessoria' | 'escola' | 'instrutor' | 'aluno' | 'somosselfieadmin' | 'somosselfieproduto';

const SELFIE_USER_ADMIN_TYPE = 'somosselfieadmin';

const SELFIE_USER_PRODUTO_TYPE = 'somosselfieproduto';

type LoginRespostaType = {
  atendente: string | boolean | null;
  created: string;
  facilitadorEc: string | boolean | null;
  id: string;
  tipo: string;
  ttl: number;
  userId: string;
  homepage: string;
};

type AuthContext = FirstcodeUserProfile;

const defaultAuthContext: AuthContext = {
  isAuthenticated: false,
};

const AuthContext = createContext<AuthContext>(defaultAuthContext);

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(`useAuth must be used within AuthProvider.`);
  }

  return context;
};

export const useProviderAuth = () => {
  const [authData, setAuthData] = useLocalStorage<AuthContext>('firstcodeUserProfile', defaultAuthContext);
  const [_, setSessionStorageAuthData] = useSessionStorage<AuthContext>('firstcodeUserProfile', defaultAuthContext);

  return {
    authenticate: async (
      username: string,
      password: string,
      type: 'DIRECT' | 'PLURALL' = 'DIRECT'
    ): Promise<LoginRespostaType> => {
      username.toLowerCase().trim();
      password.trim();

      try {
        const { data }: AxiosResponse<Omit<LoginRespostaType, 'homepage'>> = await axiosInstance.post('Users/login', {
          username,
          password,
        });

        // set Cookie
        const date = new Date(data.created);
        const now = date.getTime();

        date.setTime(now + data.ttl * 1000);

        const expires = `; expires=${date.toUTCString()}`;

        document.cookie = `firstcodeAccessToken=${data.id}${expires}; path=/`;

        const user = await UserServices.findById(username);

        let mmcodestatus: InstrutorDb['facilitadormmcode'];
        let photoUrl = user.urlFoto;
        let schoolId = user.escolaId;
        let schoolName = user.escolaNome;
        let schoolCity: string | null = null;
        let schoolUF: string | null = null;
        let escolaTemIA = false;

        if (data.tipo === 'instrutor') {
          // Será executado 2 vezes somente na primeira vez que logar (pois depois de rodar o que está dentro do if abaixo, já irá setar foto, escolaId e escolaNome)
          // Caso contrário, será executado somente uma vez (a cada login)
          // TODO: talvez ver se adicionar campo facilitadormmcode em user tbm (pois já existe um campo facilitadorEc em user)
          const facilitator = await InstrutoresServices.findById(data.userId);

          mmcodestatus = facilitator.facilitadormmcode;
        }

        if (!photoUrl || !schoolId || !schoolName) {
          if (data.tipo === 'instrutor') {
            const facilitator = await InstrutoresServices.findById(data.userId);

            mmcodestatus = facilitator.facilitadormmcode;
            schoolId = facilitator.escola;
            photoUrl = facilitator.fotoUrl || facilitator.fotoOriginalUrl;
          } else if (data.tipo === 'aluno') {
            const student = await Alunoservices.findById(data.userId);

            schoolId = student.escolaId;
            photoUrl = student.fotoUrl || student.fotoOriginalUrl;
          } else if (data.tipo === 'escola') {
            const school = await EscolasServices.buscarPorLogin(data.userId);

            schoolId = school.id;
            schoolName = school.nome;
            photoUrl = school.logoUrl || school.logoOriginalUrl;
            schoolCity = school.municipio || null;
            schoolUF = school.uf || null;
          }

          if (schoolId) {
            const { nome } = await EscolasServices.findById(schoolId);
            schoolName = nome;
          }

          if (data.tipo === 'escola' || data.tipo === 'instrutor' || data.tipo === 'aluno') {
            await UserServices.insert({ ...user, escolaId: schoolId, escolaNome: schoolName, urlFoto: photoUrl });
          }
        }

        if (schoolId) {
          const school = await EscolasServices.findById(schoolId);

          schoolCity = school.municipio || null;
          schoolUF = school.uf || null;

          try {
            const chatGPTKey = await SecretManagerKeyServices.getSchoolChatGPTSecretKey(schoolId);

            escolaTemIA = !!chatGPTKey;
          } catch {}
        }

        const userProfileAuthData = {
          username: data.userId,
          tipo: data.tipo,
          accessToken: data.id,
          facilitadormmcode: mmcodestatus,
          nome: user.nome,
          urlFoto: photoUrl,
          escolaId: schoolId,
          nomeEscola: schoolName,
          escolaCidade: schoolCity,
          escolaUF: schoolUF,
          escolaTemIA,
          serie: '',
          modulo: '',
          aula: '',
        };

        let homepage = userProfileAuthData.tipo;

        if (userProfileAuthData.tipo === 'aluno') {
          homepage = 'student';
        } else if (userProfileAuthData.tipo === 'instrutor') {
          homepage = 'facilitator';
        } else if (userProfileAuthData.tipo === 'assessoria') {
          homepage = 'analytics/#/advisor/school_engagement';
        } else if (userProfileAuthData.tipo === 'escola') {
          homepage = 'analytics/#/school/school_engagement';
        } else if (
          userProfileAuthData.tipo === SELFIE_USER_ADMIN_TYPE ||
          userProfileAuthData.tipo === SELFIE_USER_PRODUTO_TYPE
        ) {
          homepage = 'selfie';
        }

        // set Localstorage para funcionar com pagians legado
        LocalStorage.set('firstcode_accesstoken', data.id);

        let aluno = null;
        if (data.tipo === 'aluno') {
          aluno = await Alunoservices.findById(data.userId);
        }

        userProfileAuthData.modulo = aluno?.codigoModuloCorrente || '';
        userProfileAuthData.serie = aluno?.anoEducacaoBasica || '';
        userProfileAuthData.aula = aluno?.aulaCorrenteNumero?.toString() || '';

        setSessionStorageAuthData({
          ...userProfileAuthData,
          isAuthenticated: true,
          homepage,
        });

        setAuthData({
          ...userProfileAuthData,
          isAuthenticated: true,
          homepage,
        });

        if (type === 'DIRECT') {
          // window.gtag('event', 'logouDireto', {
          //   app_name: 'PlataformaMM Core',
          //   screen_name: document.title,
          //   user_id: username,
          //   profile: data.tipo,
          //   school_id: schoolId,
          // });

          if (window.location.host === 'mindmakers.cc') {
            const now = new Date();
            // Normaliza hora subtraindo 3 horas
            const dateNormalizedHours = new Date(now.getTime() - 3 * 60 * 60 * 1000);
            const dateString = dateNormalizedHours.toISOString();
            BigQueryAPI.logEventoLogin({
              userId: username,
              profile: data.tipo,
              loginTipo: 'direto',
              schoolId: schoolId,
              timestamp: dateString,
            });
          }
        }

        return { ...data, homepage };
      } catch (error) {
        console.error('useProviderAuth authenticate ', error);

        throw error;
      }
    },
    logout: async (params?: EitherMMCodeOrSelfie) => {
      try {
        SessionStorage.remove('analyticsFilters');
      } catch (e) {}

      try {
        SessionStorage.remove('profileColaboracaoAluno');

        SessionStorage.remove('tmp_' + authData.username + '_pairing');
      } catch (e) {}

      LocalStorage.remove('firstcodeUserProfile');
      LocalStorage.remove('firstcode_accesstoken');
      SessionStorage.remove('plurallCodes');
      SessionStorage.remove('firstcodePlurallData');
      SessionStorage.remove('firstcodeSSOPlurallObject');
      SessionStorage.remove('firstcodeSSOPlurallToken');
      LocalStorage.remove('firstcodeSSOPlurallObject');
      LocalStorage.remove('firstcodeSSOPlurallToken');
      LocalStorage.remove(CHATBOT_CONTEXT_LOCAL_STORAGE_KEY);

      if (authData.isAuthenticated) {
        try {
          await axiosInstance.post('Users/logout');

          setAuthData({
            isAuthenticated: false,
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('AuthProvider logout catch', { err });
        } finally {
          if (params?.isMMCode) {
            window.location.assign('/mmcode/home.html');
          } else if (params?.isSelfie) {
            window.location.assign('/selfielogin');
          } else {
            window.location.assign('/login');
          }
        }
      } else if (params?.isMMCode) {
        window.location.assign('/mmcode/home.html');
      } else if (params?.isSelfie) {
        window.location.assign('/selfielogin');
      } else {
        window.location.assign('/login');
      }
    },
    plurallIdAndProfileFromAT: async (props: {
      access_token: string;
      uuid: string;
      state: string;
    }): Promise<PlurallProfileFromAcessTokenResponse> => {
      try {
        const { access_token, uuid, state } = props;

        const { data }: AxiosResponse<PlurallProfileFromAcessTokenResponse> = await axios.post(
          '/__/auth/getProfileFromAcessToken/',
          {
            access_token,
            uuid,
            state,
          }
        );

        // console.log({ data });

        return data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('AuthProvider plurallIdAndProfileFromAT catch', error);

        throw error;
      }
    },
    authenticatePlurall: async (params: {
      ssotype: string;
      ssoidentificador: string;
      ssouuid: string;
      access_token: string;
      state: string;
    }): Promise<LoginRespostaType> => {
      try {
        const { ssotype, ssoidentificador, ssouuid, access_token, state } = params;

        const { data }: AxiosResponse<Omit<LoginRespostaType, 'homepage'>> = await axiosInstance.post('Users/login', {
          ssotype,
          ssoidentificador,
          ssouuid,
          access_token,
          state,
        });

        // set Cookie
        const date = new Date(data.created);
        const now = date.getTime();

        date.setTime(now + data.ttl * 1000);

        const expires = `; expires=${date.toUTCString()}`;

        document.cookie = `firstcodeAccessToken=${data.id}${expires}; path=/`;

        const user = await UserServices.findById(data.userId);

        let mmcodestatus: InstrutorDb['facilitadormmcode'];
        let photoUrl = user.urlFoto;
        let schoolId = user.escolaId;
        let schoolName = user.escolaNome;
        let schoolCity: string | null = null;
        let schoolUF: string | null = null;
        let escolaTemIA = false;

        if (data.tipo === 'instrutor') {
          // Será executado 2 vezes somente na primeira vez que logar (pois depois de rodar o que está dentro do if abaixo, já irá setar foto, escolaId e escolaNome)
          // Caso contrário, será executado somente uma vez (a cada login)
          // TODO: talvez ver se adicionar campo facilitadormmcode em user tbm (pois já existe um campo facilitadorEc em user)
          const facilitator = await InstrutoresServices.findById(data.userId);

          mmcodestatus = facilitator.facilitadormmcode;
        }

        if (!photoUrl || !schoolId || !schoolName) {
          if (data.tipo === 'instrutor') {
            const facilitator = await InstrutoresServices.findById(data.userId);

            mmcodestatus = facilitator.facilitadormmcode;
            schoolId = facilitator.escola;
            photoUrl = facilitator.fotoUrl || facilitator.fotoOriginalUrl;
          } else if (data.tipo === 'aluno') {
            const student = await Alunoservices.findById(data.userId);

            schoolId = student.escolaId;
            photoUrl = student.fotoUrl || student.fotoOriginalUrl;
          } else if (data.tipo === 'escola') {
            const school = await EscolasServices.buscarPorLogin(data.userId);

            schoolId = school.id;
            schoolName = school.nome;
            photoUrl = school.logoUrl || school.logoOriginalUrl;
            schoolCity = school.municipio || null;
            schoolUF = school.uf || null;
          }

          if (schoolId) {
            const { nome } = await EscolasServices.findById(schoolId);
            schoolName = nome;
          }

          if (data.tipo === 'escola' || data.tipo === 'instrutor' || data.tipo === 'aluno') {
            await UserServices.insert({ ...user, escolaId: schoolId, escolaNome: schoolName, urlFoto: photoUrl });
          }
        }

        if (schoolId) {
          const school = await EscolasServices.findById(schoolId);

          schoolCity = school.municipio || null;
          schoolUF = school.uf || null;

          try {
            const chatGPTKey = await SecretManagerKeyServices.getSchoolChatGPTSecretKey(schoolId);

            escolaTemIA = !!chatGPTKey;
          } catch {}
        }

        const userProfileAuthData = {
          username: data.userId,
          tipo: data.tipo,
          accessToken: data.id,
          facilitadormmcode: mmcodestatus,
          nome: user.nome,
          urlFoto: photoUrl,
          escolaId: schoolId,
          nomeEscola: schoolName,
          escolaCidade: schoolCity,
          escolaUF: schoolUF,
          escolaTemIA,
          serie: '',
          modulo: '',
          aula: '',
        };

        let homepage = userProfileAuthData.tipo;

        if (userProfileAuthData.tipo === 'aluno') {
          homepage = 'student';
        } else if (userProfileAuthData.tipo === 'instrutor') {
          homepage = 'facilitator';
        } else if (userProfileAuthData.tipo === 'assessoria') {
          homepage = 'analytics/#/advisor/school_engagement';
        } else if (userProfileAuthData.tipo === 'escola') {
          homepage = 'analytics/#/school/school_engagement';
        } else if (
          userProfileAuthData.tipo === SELFIE_USER_ADMIN_TYPE ||
          userProfileAuthData.tipo === SELFIE_USER_PRODUTO_TYPE
        ) {
          homepage = 'selfie';
        }

        let aluno = null;
        if (data.tipo === 'aluno') {
          aluno = await Alunoservices.findById(data.userId);
        }

        userProfileAuthData.modulo = aluno?.codigoModuloCorrente || '';
        userProfileAuthData.serie = aluno?.anoEducacaoBasica || '';
        userProfileAuthData.aula = aluno?.aulaCorrenteNumero?.toString() || '';

        setAuthData({
          ...userProfileAuthData,
          isAuthenticated: true,
          homepage,
        });

        // set Localstorage para funcionar com pagians legado
        LocalStorage.set('firstcode_accesstoken', data.id);

        SessionStorage.set(
          'firstcodePlurallData',
          JSON.stringify({
            userMM: data.userId,
            accessToken: access_token,
            dateAccessToken: new Date(),
          })
        );

        return { ...data, homepage };
      } catch (error) {
        console.error('AuthProvider authenticatePlurall catch', error);

        throw error;
      }
    },
  };
};

export const AuthProvider = (props: { children: React.ReactNode }) => {
  const [authContextValue] = useLocalStorage<AuthContext>('firstcodeUserProfile', defaultAuthContext);

  return (
    <AuthContext.Provider
      value={{
        ...authContextValue,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

import { useCallback, useEffect, useState } from 'react';

import useEventListener from './useEventListener';

import LocalStorage from 'util/local-storage';
import { parseJSON } from 'util/parseJSON';

type Value<T> = T | null;

function useReadLocalStorage<T>(key: string): Value<T> {
  // Get from local storage then

  // parse stored json or return initialValue

  const readValue = useCallback((): Value<T> => {
    // Prevent build error "window is undefined" but keep keep working

    if (typeof window === 'undefined') {
      return null;
    }

    try {
      const item = LocalStorage.get(key);

      return item ? (parseJSON(item) as T) : null;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);

      return null;
    }
  }, [key]);

  // State to store our value

  // Pass initial state function to useState so logic is only executed once

  const [storedValue, setStoredValue] = useState<Value<T>>(readValue);

  // Listen if localStorage changes

  useEffect(() => {
    setStoredValue(readValue());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStorageChange = useCallback(() => {
    setStoredValue(readValue());
  }, [readValue]);

  // this only works for other documents, not the current one

  useEventListener('storage', handleStorageChange);

  // this is a custom event, triggered in writeValueToLocalStorage

  // See: useLocalStorage()

  useEventListener('local-storage', handleStorageChange);

  return storedValue;
}

export default useReadLocalStorage;

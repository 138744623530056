import { AxiosPromise, AxiosResponse } from 'axios';

import { axiosInstance } from 'lib/axios';
import LocalStorage from 'util/local-storage';

import type { AlunoComResponsavel } from './TurmaService';

export type AlunosAtivosType = Pick<
  AlunoDb,
  | 'login'
  | 'alunoTeste'
  | 'anoEducacaoBasica'
  | 'max'
  | 'nome'
  | 'turmas'
  | 'responsavelId'
  | 'ultimaTurmaMatriculado'
  | 'turmasMax'
  | 'ultimaTurmaMatriculadoMax'
  | 'status'
  | 'subTurma'
  | 'letraTurma'
  | 'turnoTurma'
  | 'codigoModuloCorrente'
>;

export interface EscolaInstrutores {
  login: string;
  nome: string;
  email: string;
  status: string;
  pwd?: string;
  apelido?: string;
  sexo?: string;
  telefone?: string;
  escola?: string;
  turmas?: string;
  turmasAutoestudo?: string;
  escolaEAtivo?: string;
  usuarioCriacao?: string;
  dataCriacao?: string;
  usuarioAtualizacao?: string;
  dataAtualizacao?: string;
  fotoUrl?: string;
  fotoOriginalUrl?: string;
  grupoEnvioEmail?: string;
  facilitadorEc?: boolean;
  dataNascimento?: string;
  tamanhoCamiseta?: string;
  turmasProva?: string;
}

interface EscolaInstrutoresRoot {
  instrutores: Array<EscolaInstrutores>;
}

export type StudentLoginNameStatus = Pick<AlunoDb, 'login' | 'nome' | 'status'>;

export const EscolasServices = {
  async list(): Promise<Array<EscolaDb>> {
    const { data }: AxiosResponse<Array<EscolaDb>> = await axiosInstance.get('Escolas');
    return data;
  },
  async exists(id: string): Promise<AxiosPromise> {
    const { data } = await axiosInstance.get(`Escolas/${id}/exists`);
    return data;
  },
  async findById(id: string): Promise<EscolaDb> {
    const { data }: AxiosResponse<EscolaDb> = await axiosInstance.get(`Escolas/${id}`);

    return data;
  },
  async buscarPorLogin(loginEscola: string): Promise<EscolaDb> {
    const { data }: AxiosResponse<EscolaDb> = await axiosInstance.get(
      `Escolas/buscarPorLogin?loginEscola=${loginEscola}`
    );
    return data;
  },
  async findByPlurallId(schoolPlurallId: string): Promise<EscolaDb> {
    const { data }: AxiosResponse<EscolaDb> = await axiosInstance.get(
      `Escolas/findByPlurallId?escolaPlurallId=${schoolPlurallId}`
    );

    return data;
  },
  async delete(id: string): Promise<AxiosPromise> {
    const { data } = await axiosInstance.delete(`Escolas/${id}`);
    return data;
  },

  async insert(payload: EscolaDb): Promise<AxiosPromise> {
    const { data } = await axiosInstance.put('Escolas/', payload);
    return data;
  },

  async update(payload: EscolaDb): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('Escolas', payload);
    return data;
  },
  async getEscola(accessToken: string, idEscolaRecebido: string): Promise<EscolaDb> {
    const { data }: AxiosResponse<EscolaDb> = await axiosInstance.get(
      `Escolas/recuperarEscola?accessToken=${accessToken}&idEscolaRecebido=${idEscolaRecebido}`
    );
    return data;
  },
  async listarPorEscola(accessToken: string): Promise<Array<AlunosAtivosType>> {
    const { data } = await axiosInstance.get(`Escolas/alunos?accessToken=${accessToken}`);
    return data;
  },
  async listarAtivosPorEscola(accessToken: string, idEscolaRecebido: string): Promise<Array<AlunosAtivosType>> {
    const { data } = await axiosInstance.get(
      `Escolas/alunosAtivos?accessToken=${accessToken}&idEscolaRecebido=${idEscolaRecebido}`
    );
    return data;
  },
  async listarAlunosComStatus(
    accessToken: string,
    idEscolaRecebido: string,
    status: string
  ): Promise<Array<StudentLoginNameStatus>> {
    const { data } = await axiosInstance.get(
      `Escolas/alunosComStatus?accessToken=${accessToken}&idEscolaRecebido=${idEscolaRecebido}&status=${status}`
    );
    return data;
  },
  async listarTurmasComSituacaoETipo({
    accessToken,
    idEscolaRecebido,
    situacao,
    tipo,
  }: {
    accessToken: string;
    idEscolaRecebido: string;
    situacao: string;
    tipo: string;
  }): Promise<Array<Pick<TurmaDb, 'id' | 'nome' | 'modulo' | 'cursoCodigo'>>> {
    const { data } = await axiosInstance.get(
      `Escolas/turmasComSituacaoETipo?accessToken=${accessToken}&idEscolaRecebido=${idEscolaRecebido}&situacao=${situacao}&tipo=${tipo}`
    );
    return data;
  },
  async GetInstrutores(accessToken: string): Promise<EscolaInstrutoresRoot> {
    const { data } = await axiosInstance.get(`Escolas/instrutores?accessToken=${accessToken}`);
    return data;
  },
  async recuperarInstrutoresPorEscola(idEscola: string): Promise<Array<InstrutorDb>> {
    const { data }: AxiosResponse<Array<InstrutorDb>> = await axiosInstance.get(
      `Escolas/recuperarInstrutorPorEscola?idEscola=${idEscola}`
    );

    return data;
  },
  async buscaPorTipo(tipo: string): Promise<Array<UserDb>> {
    const { data }: AxiosResponse<Array<UserDb>> = await axiosInstance.get(`Users/buscaPorTipo?tipo=${tipo}`);
    return data;
  },

  async dadosDosAlunosComResponsaveis(idEscolaRecebido: string): Promise<Array<AlunoComResponsavel>> {
    const accessToken = LocalStorage.get('firstcode_accesstoken');

    const { data }: AxiosResponse<Array<AlunoComResponsavel>> = await axiosInstance.get(
      `Escolas/dadosResponsaveisAlunos?accessToken=${accessToken}&idEscolaRecebido=${idEscolaRecebido}`
    );

    return data;
  },
  async recuperarEscolasPlurall(
    idsPlurallEscolas: string[]
  ): Promise<Array<Pick<EscolaDb, 'id' | 'idPlurall' | 'nome' | 'municipio' | 'uf'>>> {
    const { data } = await axiosInstance.get(
      `Escolas/recuperaEscolasPlurall?idsPlurallEscolas=${idsPlurallEscolas.join(',')}`
    );

    return data;
  },
  async getIdPlurallEscola(idEscola: string): Promise<string | null> {
    const { data } = await axiosInstance.get(`Escolas/getIdPlurallEscola?idEscola=${idEscola}`);

    return data;
  },
  async possuiSomenteCursosMMCode(escolaId: string): Promise<boolean> {
    const { data } = await axiosInstance.get(`Escolas/possuiSomenteCursosMMCode?escolaId=${escolaId}`);

    return data;
  },
};


/* eslint-disable no-console */
import axios from 'axios';

const NOT_FOUND = 404;
const MODEL_NOT_FOUND_CODE = 'MODEL_NOT_FOUND';

// Configuração geral cliente axios para todo o app

const API_URL = import.meta.env.REACT_APP_API_URL || '/api/';

const axiosInstance = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

// Exemplos extras de intercept direto no axios client

const responseSuccessHandler = (response: any) => {
  return response;
};

const responseErrorHandler = (error: any) => {
  if (error.response) {
    const { data, status, headers } = error.response;

    if ((!!data.error?.code && data.error.code !== MODEL_NOT_FOUND_CODE) || status !== NOT_FOUND) {
      console.error('Error - axios.ts - error.response');
      console.error({ data, status });

      if (import.meta.env.DEV) {
        console.error({ headers });
      }
    }
  } else if (error.request) {
    const { request } = error;
    console.error('Error - axios.ts - error.request');
    console.error({ request });
  } else {
    console.error('Error - axios.ts', error.message);
  }

  if (import.meta.env.DEV && error.config) {
    const { config } = error;
    console.error('Error - axios.ts - error.config');
    console.error({ config });
  }

  if (error?.response?.status === 401 || error?.response?.status === 403) {
    // Add your logic to
    //  1. Redirect user to LOGIN
    //  2. Reset authentication from localstorage/sessionstorage

    // codigos de falha User.login()
    // LOGIN_FAILED_SSO
    // SSO_FAILED
    // LOGIN_FAILED

    if (error.config.url !== 'Users/login') window.location.assign('/mmcode');
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

export { axiosInstance };

import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { BigQueryDatetime } from '@google-cloud/bigquery';

import type { OriginValues } from 'services/api/RedirectService';
import type { AcessoAgrupadoNome } from './AnalyticsAPI';
import { AcessoLogVideos } from 'pages/Analytics/Details/VideosLogsDetails';
import { AnalyticsUsersSchoolFilter } from 'pages/Analytics/AnalyticsRoute';
import { AlunosAtivosType } from 'services/api/EscolasService';

const axiosInstance = axios.create({
  responseType: 'json',
});

type LinkLog = {
  urlencurtada: string | null | undefined;
  urlfinal: string | null | undefined;
  datahora: BigQueryDatetime | null | undefined;
  tipodispositivo: 'mobile' | 'desktop' | null | undefined;
  modelodispositivo: string | null | undefined;
  origem: OriginValues | null | undefined;
  cidade: string | null | undefined;
  estado: string | null | undefined;
  perfil: string | null | undefined;
  login: string | null | undefined;
};

export type AcessoLink = {
  id: string;
  dia: string;
  totalacessos: number;
  licaonome: string;
  nome: string;
  tipo: string;
};

export type AcessoEvento = {
  totalacessos: number;
  dia: string;
};

export type EscolaEnviosEmail = {
  envios: number;
  escola: string;
  escolaid: string;
};

export type EscolaEnviosEmailPorAlunado = {
  envios: number;
  enviosporalunado: number;
  escola: string;
  escolaid: string;
};

export type RemetenteEmail = {
  escola: string;
  facilitador: string;
  email: string;
};

export type DestinatarioEmail = {
  email: string;
};

export type EnviosEmailPorDia = {
  envios: number;
  dia: string;
};

export type ClicksEmailPorDia = {
  clicks: number;
  dia: string;
};

type FilterParam = {
  initDateFilter?: number;
  endDateFilter?: number;
  limit?: number;
  hourOfCode?: boolean;
};

export type FilterPaginasParam = {
  initialDate?: string;
  finalDate?: string;
  limit?: number;
  school_id?: string;
  facilitator?: string;
  advisor?: string;
  filteredSchools?: string[];
  hourofcode?: boolean;
  tipo?: string;
  origem?: string;
};

export type AulasTopicosCurriculares = {
  licaocodigo: string;
  licaonome: string;
  modulocodigo: string;
  cursocodigo: string;
};

export type LicaoTopicosCurriculares = {
  idtopico: string;
  codigopadraocurricular: string;
  descricao: string;
  siglainstituicao: string;
  aulas: Array<AulasTopicosCurriculares>;
};

export type FilterRitmoAulasParam = Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>;
export type FilterFechamentoAulasParam = Pick<AnalyticsUsersSchoolFilter, 'dateInterval'> & { modulo: string };

export type FilterProvasCategoriasParam = Pick<
  AnalyticsUsersSchoolFilter,
  'dateInterval' | 'currentSchool' | 'currentAdvisor' | 'filteredSchools'
>;
export type FilterProvasQuestoesParam = Pick<
  AnalyticsUsersSchoolFilter,
  'dateInterval' | 'currentSchool' | 'filteredSchools'
>;

export type FilterPaginasEscolaParam = {
  initialDate: string;
  finalDate: string;
  limit: number;
  school_id?: string;
  filteredSchools?: Array<string>;
  advisor?: string;
  currentFacilitator?: string;
};

export type FilterPaginasDateParam = {
  date?: string;
  limit?: number;
  school_id?: string;
  facilitator?: string;
  advisor?: string;
  filteredSchools?: Array<string>;
  hourofcode?: boolean;
  tipo?: string;
  origem?: string;
};

export type AcessoTotal = {
  dia: string;
  acessos: number;
};

export type LogsFinalizacao = {
  dataHoraFinalizacaoAula: string;
  dataHoraInicioAula: string;
  login: string;
  profile: string;
  title: string;
  escolaNome: string;
};

export type RankFinalizacaoEscola = {
  escola: string;
  escolanome: string;
  acessos: number;
};

export type RankFinalizacaoEscolaPorAlunado = {
  escola: string;
  escolanome: string;
  acessos: number;
  acessosporalunado: number;
};

export type LogsAcessoAlunoModulo = {
  origem: string;
  moduloid: string;
  escolaNome: string | null;
  login: string;
  totalacessos: number;
  dia: string;
};

export type LogsAcessosUserDia = {
  dia: string;
  login: string;
  escolaNome: string;
  totalacessos: number;
};

type FilterDateParams = {
  initialDate: string;
  finalDate: string;
  escola?: string;
  filteredSchools?: Array<string>;
  advisor?: string;
};

type LessonEvaluationAverageByDay = {
  dia: string;
  mediaavaliacaotempo: number;
  mediaavaliacaoconteudo: number;
  totalavaliacoes: number;
  escolaid: string;
  facilitadorid: string;
};

type LessonStudentEvaluationAverageByDay = {
  dia: string;
  mediaavaliacoes: number;
  totalavaliacoes: number;
  escolaid: string;
  facilitador: string;
};

type LessonStudentEvaluation = {
  alunologin: string;
  dia: string;
  escolaid: string;
  instrutor: string;
  licaocodigo: string;
  nota: number;
  turma: string;
};

type LessonEvaluationAverageByDayByLessonByFacilitator = {
  id: string;
  dia: string;
  mediaavaliacaotempo: number;
  mediaavaliacaoconteudo: number;
  totalavaliacoes: number;
  licaoid: string;
  facilitadorid: string;
};

type StudentsChallengesByDay = {
  escolaid: string;
  tipo: 'MMBlockly' | 'mmCode';
  dia: string;
  totalacessos: number;
};

export type LessonEvaluationAverageByDayByLessonByFacilitatorWithSchool =
  LessonEvaluationAverageByDayByLessonByFacilitator & {
    escolaid: string;
  };

export type StudentChallengesInfoAndAveragesByDay = {
  escolaid: string | null;
  dia: string;
  modulo: string | null;
  ambiente: string | null;
  fase: string | null;
  desafio: number;
  media_acertos: number | null;
  media_tempogasto_segs: number | null;
  media_numerotentativas: number | null;
  codigo_atividade_id: string | null;
};

export type QuestoesInfoAndAveragesByDay = {
  escolaid: string | null;
  dia: string;
  modulo: string | null;
  nomeprova: string | null;
  questao: number;
  media_acertos: number | null;
  media_tempogasto_segs: number | null;
  media_numerotentativas: number | null;
  idprovaaplicada: string | null;
};

export type TotalAcessosRank = {
  escolaid: string;
  escolanome: string;
  totalaluno: number;
  totalinstrutor: number;
  total: number;
};

export type TotalAcessosRankPorAlunado = {
  escolaid: string;
  escolanome: string;
  totalaluno: number;
  totalinstrutor: number;
  total: number;
  totalporalunado: number;
};

export type RitmoAula = {
  datafim: string;
  escolaid: string;
  modulo: string;
  facilitadorid: string;
  total_modulo: number;
  total_por_aula: Record<string, number>;
};

export type FechamentoAula = {
  escolaid: string | null;
  escolanome: string | null;
  turmaid: string;
  turmanome: string | null;
  modulo: string;
  facilitadores: Array<{ login: string; nome: string }>;
  vigencia_em_segundos: number;
  aulacodigo: string;
  datahorainicio: string;
  datahorafim: string;
};

export type ProvaCategoria = {
  modulo: string;
  dataaplicacao: string;
  escolaid: string;
  tipo: string | null;
  totalavaliacoes: number;
};

export type ProvaQuestoes = {
  idprovaaplicada: string;
  idmodeloavaliacao: string;
  versaomodelo: string | null;
  nomeprova: string | null;
  escolaid: string;
  datahorainicio: string;
  datahorafim: string;
  iduser: string;
  modulo: string;
  tipo: string | null;
  questoes: Array<{
    idquestao: string;
    numeroquestao: number;
    itemselecionado: number;
    acertou: boolean;
    datahorainicio: string | null;
    datahorafim: string | null;
    duracao_segundos: number;
  }>;
  duracao_segundos: number;
  dataaplicacao: string | null;
};

export type OcorrenciaTypeBigquery = {
  numero: number;
  assunto: string;
  atendentelogin: string;
  atendentenome: string;
  atendenteemail: string;
  escola: string;
  escolanome: string;
  requerentenome: string;
  requerenteperfil: string;
  requerentelogin: string;
  datacriacao: string;
  situacao: string;
};

type EmailInvalido = {
  destinatarioNome: string;
  destinatarioEmail: string;
  escolaId: string;
  escolaNome: string;
  assunto: string;
  remetenteNome: string;
  remetenteEmail: string;
  razao: string | null;
  smtpId: string | null;
  dataHora: string;
};

export type PageViewLogType = {
  user_id: string;
  profile: string;
  school_id: string;
  page: string;
  title: string;
  timestamp: string;
};

export type EventoLoginLogType = {
  userId: string;
  profile: string;
  loginTipo: 'direto' | 'plurall' | 'cardplurall';
  schoolId?: string | null | undefined;
  servicoPlurall?: string | null | undefined;
  schoolPlurallId?: string | null | undefined;
  schoolCnpj?: string | null | undefined;
  timestamp: string;
};

export type EventoDeuPresencaLogType = {
  userId?: string | null | undefined;
  profile?: string | null | undefined;
  schoolId?: string | null | undefined;
  timestamp: string;
  pagina_titulo?: string;
};

export type EventoFinalizouAutoestudoLogType = {
  userId?: string | null | undefined;
  profile?: string | null | undefined;
  schoolId?: string | null | undefined;
  autoestudo?: string | null | undefined;
  timestamp: string;
  pagina_titulo?: string;
};

export type EventoFinalizouAulaLogType = {
  user_id: string | null | undefined;
  idEscola: string | null | undefined;
  escolanome: string | null | undefined;
  profile: string | null | undefined;
  datahora_inicio_aula: string | null | undefined;
  datahora_fim_aula: string | null | undefined;
  turmaid: string | null | undefined;
  turmanome: string | null | undefined;
  aulacodigo: string | null | undefined;
  facilitadores: Array<{ login: string; nome: string }>;
  modulo_formatado: string | null | undefined;
  alunos?: string[] | null | undefined;
  turma_teste: boolean;
  pagina_titulo?: string;
};

export type FinalizouLicaoAutoestudoType = {
  aulaCodigo?: string;
  codigoTexto?: string;
  aulaOrdem?: number;
  aulaNome?: string;
  moduloNome?: string;
  cursoCodigo?: string | null | undefined;
  modulo?: string | null | undefined;
  dataHoraInicio: string | null | undefined;
  dataHoraFinalizacao: string | null | undefined;
  duracaoEmSegundos: number;
  login?: string;
  escola?: string | null | undefined;
  tipo?: string | null | undefined;
};

export type TempoAgrupadoAutoestudo = {
  segundos: number;
  autoestudo: string;
};

type FilterGeneralPanelLessonEvaluationParams = Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>;

type FilterPeriodSchoolSummary = Pick<AnalyticsUsersSchoolFilter, 'currentSchool' | 'dateInterval'>;

const BigQueryAPI = {
  async getLinks(): Promise<Array<LinkLog>> {
    const { data }: AxiosResponse<Array<LinkLog>> = await axiosInstance.get(`/bigquery/getLinkLogs`);

    return data;
  },
  async getAcessosVideos(): Promise<Array<AcessoLink>> {
    const { data }: AxiosResponse<Array<AcessoLink>> = await axiosInstance.get(`/bigquery/getAcessosVideos`);

    return data;
  },
  async getAcessosVideosTipo(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    > & { tipo: string }
  ): Promise<Array<AcessoTotal>> {
    const { data }: AxiosResponse<Array<AcessoTotal>> = await axiosInstance.get(`/bigquery/getAcessosVideosTipo`, {
      params: {
        tipo: filters.tipo,
        dataInicio: filters.dateInterval.initialDate,
        dataFim: filters.dateInterval.finalDate,
        idEscola: filters.currentSchool,
        facilitador: filters.currentFacilitator?.login,
        filteredSchools: filters.filteredSchools?.join(','),
      },
    });

    return data;
  },
  async getAcessosVideosTipoDia(
    filters: { dateInterval?: AnalyticsUsersSchoolFilter['dateInterval'] } & Pick<
      AnalyticsUsersSchoolFilter,
      'specificDate' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator' | 'videoType'
    >
  ): Promise<Array<AcessoLogVideos>> {
    const params: Record<string, string | undefined> = {
      videoType: filters.videoType,
      idEscola: filters.currentSchool,
      facilitador: filters.currentFacilitator?.login,
      filteredSchools: filters.filteredSchools?.join(','),
    };

    if (filters.dateInterval) {
      params.startDate = filters.dateInterval.initialDate;
      params.endDate = filters.dateInterval.finalDate;
    } else if (filters.specificDate) {
      params.date = filters.specificDate;
    }

    const { data }: AxiosResponse<Array<AcessoLogVideos>> = await axiosInstance.get(
      `/bigquery/getAcessosVideosTipoDia`,
      {
        params,
      }
    );

    return data;
  },
  async getFechamentoAulasAcessos(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    > & {
      turmaTeste?: boolean | null | undefined;
    }
  ): Promise<Array<AcessoTotal>> {
    const { data }: AxiosResponse<Array<AcessoTotal>> = await axiosInstance.get(`/bigquery/getFechamentoAulasAcessos`, {
      params: {
        dataInicio: filters.dateInterval.initialDate,
        dataFim: filters.dateInterval.finalDate,
        idEscola: filters.currentSchool,
        login: filters.currentFacilitator?.login,
        filteredSchools: filters.filteredSchools?.join(','),
        turmaTeste: filters.turmaTeste,
      },
    });

    return data;
  },
  async getFinalizacaoAulasAcessos(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    >
  ): Promise<Array<AcessoTotal>> {
    const { data }: AxiosResponse<Array<AcessoTotal>> = await axiosInstance.get(
      `/bigquery/getFinalizacaoAulasAcessos`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          login: filters.currentFacilitator?.login,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getFinalizacaoAulasDia(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'specificDate' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    >
  ): Promise<Array<LogsFinalizacao>> {
    const { data }: AxiosResponse<Array<LogsFinalizacao>> = await axiosInstance.get(
      `/bigquery/getFinalizacaoAulasDia`,
      {
        params: {
          data: filters.specificDate,
          idEscola: filters.currentSchool,
          facilitador: filters.currentFacilitator?.login,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getFinalizacaoAulasRanking(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ): Promise<Array<RankFinalizacaoEscola>> {
    const { data }: AxiosResponse<Array<RankFinalizacaoEscola>> = await axiosInstance.get(
      `/bigquery/getFinalizacaoAulasRanking`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getAlunosEngajadosRanking(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ): Promise<Array<RankFinalizacaoEscola>> {
    const { data }: AxiosResponse<Array<RankFinalizacaoEscola>> = await axiosInstance.get(
      `/bigquery/getAlunosEngajadosRanking`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getDesafiosRanking(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ): Promise<Array<RankFinalizacaoEscola>> {
    const { data }: AxiosResponse<Array<RankFinalizacaoEscola>> = await axiosInstance.get(
      `/bigquery/getDesafiosRanking`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getAcessosPaginas(filters?: FilterParam): Promise<Array<AcessoAgrupadoNome>> {
    const { data }: AxiosResponse<Array<AcessoAgrupadoNome>> = await axiosInstance.get(
      `/bigquery/getAcessosPaginas?limit=${filters?.limit ?? 5}&dataInicio=${filters?.initDateFilter ?? ''}&dataFim=${
        filters?.endDateFilter ?? ''
      }&idEscola=${''}&login=${''}`
    );

    return data;
  },
  async getAcessosPaginasFiltradas(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    > & { limit?: number; hourofcode?: true }
  ): Promise<Array<AcessoAgrupadoNome>> {
    const { data }: AxiosResponse<Array<AcessoAgrupadoNome>> = await axiosInstance.get(
      `/bigquery/getAcessosPaginasFiltradas`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          limit: filters.limit,
          hourofcode: filters.hourofcode,
          idEscola: filters.currentSchool,
          facilitador: filters.currentFacilitator?.login,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getAcessosPaginasFiltradasDia(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    > & { limit?: number; hourofcode?: true }
  ): Promise<Array<AcessoAgrupadoNome>> {
    const { data }: AxiosResponse<Array<AcessoAgrupadoNome>> = await axiosInstance.get(
      `/bigquery/getAcessosPaginasFiltradasDia`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          limit: filters.limit,
          idEscola: filters.currentSchool,
          facilitador: filters.currentFacilitator?.login,
          filteredSchools: filters.filteredSchools?.join(','),
          hourofcode: filters.hourofcode,
        },
      }
    );

    return data;
  },
  async getAcessosBlocklyFiltradas(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'currentFacilitator' | 'filteredSchools'
    > & { limit?: number }
  ): Promise<Array<AcessoAgrupadoNome>> {
    const { data }: AxiosResponse<Array<AcessoAgrupadoNome>> = await axiosInstance.get(
      `/bigquery/getAcessosBlocklyFiltradas`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          facilitador: filters.currentFacilitator?.login,
          filteredSchools: filters.filteredSchools?.join(','),
          limit: filters.limit,
        },
      }
    );

    return data;
  },

  async getAcessosCodeFiltradas(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'currentFacilitator' | 'filteredSchools'
    > & { limit?: number }
  ): Promise<Array<AcessoAgrupadoNome>> {
    const { data }: AxiosResponse<Array<AcessoAgrupadoNome>> = await axiosInstance.get(
      `/bigquery/getAcessosCodeFiltradas`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          facilitador: filters.currentFacilitator?.login,
          filteredSchools: filters.filteredSchools?.join(','),
          limit: filters.limit,
        },
      }
    );

    return data;
  },

  async getAcessosUnicosDias(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    > & { tipo: 'instrutor' | 'aluno'; origem: 'direto' | 'plurall'; alunoTeste?: boolean | null | undefined }
  ): Promise<Array<AcessoAgrupadoNome>> {
    const { data }: AxiosResponse<Array<AcessoAgrupadoNome>> = await axiosInstance.get(
      `/bigquery/getAcessosUnicosDias`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          tipo: filters.tipo,
          origem: filters.origem,
          idEscola: filters.currentSchool,
          facilitador: filters.currentFacilitator?.login,
          filteredSchools: filters.filteredSchools?.join(','),
          alunoTeste: filters.alunoTeste,
        },
      }
    );

    return data;
  },
  async getAcessosUnicosPeriodoDias(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    > & { tipo: 'instrutor' | 'aluno'; origem: 'direto' | 'plurall'; alunoTeste?: boolean | null | undefined }
  ): Promise<Array<AcessoAgrupadoNome>> {
    const { data }: AxiosResponse<Array<AcessoAgrupadoNome>> = await axiosInstance.get(
      `/bigquery/getAcessosUnicosPeriodoDias`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          tipo: filters.tipo,
          origem: filters.origem,
          idEscola: filters.currentSchool,
          facilitador: filters.currentFacilitator?.login,
          filteredSchools: filters.filteredSchools?.join(','),
          alunoTeste: filters.alunoTeste,
        },
      }
    );

    return data;
  },
  async getAcessosAlunosModuloUnicosDias(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'> & {
      tipo: 'kids' | 'teens';
      alunoTeste?: boolean | null | undefined;
    }
  ): Promise<Array<AcessoAgrupadoNome>> {
    const { data }: AxiosResponse<Array<AcessoAgrupadoNome>> = await axiosInstance.get(
      `/bigquery/getAcessosAlunosModuloUnicosDias`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          tipo: filters.tipo,
          idEscola: filters.currentSchool,
          filteredSchools: filters.filteredSchools?.join(','),
          alunoTeste: filters.alunoTeste,
        },
      }
    );

    return data;
  },
  async getAcessosAlunosModulo(
    filters: Pick<AnalyticsUsersSchoolFilter, 'specificDate' | 'currentSchool' | 'filteredSchools'> & { curso: string }
  ): Promise<Array<LogsAcessoAlunoModulo>> {
    const { data }: AxiosResponse<Array<LogsAcessoAlunoModulo>> = await axiosInstance.get(
      `/bigquery/getAcessosAlunosModulo`,
      {
        params: {
          data: filters.specificDate,
          curso: filters.curso,
          idEscola: filters.currentSchool,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getAcessosUnicosDetalhesDia(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'specificDate' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    > & { tipo: 'instrutor' | 'aluno'; origem: string }
  ): Promise<Array<LogsAcessosUserDia>> {
    const { data }: AxiosResponse<Array<LogsAcessosUserDia>> = await axiosInstance.get(
      `/bigquery/getAcessosUnicosDetalhesDia`,
      {
        params: {
          data: filters.specificDate,
          origem: filters.origem,
          tipo: filters.tipo,
          idEscola: filters.currentSchool,
          facilitador: filters.currentFacilitator?.login,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getAcessosRank(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ): Promise<Array<TotalAcessosRank>> {
    const { data }: AxiosResponse<Array<TotalAcessosRank>> = await axiosInstance.get(`/bigquery/getAcessosRank`, {
      params: {
        dataInicio: filters.dateInterval.initialDate,
        dataFim: filters.dateInterval.finalDate,
        idEscola: filters.currentSchool,
        filteredSchools: filters.filteredSchools?.join(','),
      },
    });

    return data;
  },
  async getEnviosEmailsPorDia(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ): Promise<Array<EnviosEmailPorDia>> {
    const { data }: AxiosResponse<Array<EnviosEmailPorDia>> = await axiosInstance.get(
      `/bigquery/getEnviosEmailsPorDia`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getRankingEscolaEmails(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'> & { limit?: number }
  ): Promise<Array<EscolaEnviosEmail>> {
    const { data }: AxiosResponse<Array<EscolaEnviosEmail>> = await axiosInstance.get(
      `/bigquery/getRankingEscolaEmails`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          limit: filters.limit,
          idEscola: filters.currentSchool,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getQuantidadeEmailsSuporteDia(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>
  ): Promise<Array<AcessoAgrupadoNome>> {
    const { data }: AxiosResponse<Array<AcessoAgrupadoNome>> = await axiosInstance.get(
      `/bigquery/getQuantidadeEmailsSuporteDia`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
        },
      }
    );

    return data;
  },
  async getRemetentesSemGrupo(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ): Promise<Array<RemetenteEmail>> {
    const { data }: AxiosResponse<Array<RemetenteEmail>> = await axiosInstance.get(`/bigquery/getRemetentesSemGrupo`, {
      params: {
        dataInicio: filters.dateInterval.initialDate,
        dataFim: filters.dateInterval.finalDate,
        idEscola: filters.currentSchool,
        filteredSchools: filters.filteredSchools?.join(','),
      },
    });

    return data;
  },
  async getDestinatariosInvalidosSendgrid(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ): Promise<Array<DestinatarioEmail>> {
    const { data }: AxiosResponse<Array<DestinatarioEmail>> = await axiosInstance.get(
      `/bigquery/getDestinatariosInvalidosSendgrid`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          escolaId: filters.currentSchool,
          escolas: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getEmailsInvalidos(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ): Promise<Array<EmailInvalido>> {
    const { data }: AxiosResponse<Array<EmailInvalido>> = await axiosInstance.get(
      `/bigquery/envioErroEmailsInvalidos`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          escolaId: filters.currentSchool,
          escolas: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getEmailsRecebidosPorDiaSendgrid(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ): Promise<Array<EnviosEmailPorDia>> {
    const { data }: AxiosResponse<Array<EnviosEmailPorDia>> = await axiosInstance.get(
      `/bigquery/getEmailsRecebidosPorDiaSendgrid`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          escolaId: filters.currentSchool,
          escolas: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getTotalLinksClicadosDiaSendgrid(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ): Promise<Array<ClicksEmailPorDia>> {
    const { data }: AxiosResponse<Array<ClicksEmailPorDia>> = await axiosInstance.get(
      `/bigquery/getTotalLinksClicadosDiaSendgrid`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          escolaId: filters.currentSchool,
          escolas: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getAvaliacaoGeralLicaoDia(
    filters: AnalyticsUsersSchoolFilter['dateInterval']
  ): Promise<Array<LessonEvaluationAverageByDay>> {
    const { data }: AxiosResponse<Array<LessonEvaluationAverageByDay>> = await axiosInstance.get(
      `/bigquery/getAvaliacaoGeral`,
      {
        params: {
          dataInicio: filters.initialDate,
          dataFim: filters.finalDate,
        },
      }
    );

    return data;
  },
  async getAvaliacaoLicaoDias(
    filters: FilterGeneralPanelLessonEvaluationParams
  ): Promise<Array<LessonEvaluationAverageByDayByLessonByFacilitatorWithSchool>> {
    const { data }: AxiosResponse<Array<LessonEvaluationAverageByDayByLessonByFacilitatorWithSchool>> =
      await axiosInstance.get(`/bigquery/getAvaliacaoLicaoDias`, {
        params: filters.dateInterval,
      });

    return data;
  },
  async getTotalStudentsChallengesByDay(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentAdvisor'>
  ): Promise<Array<StudentsChallengesByDay>> {
    const { data }: AxiosResponse<Array<StudentsChallengesByDay>> = await axiosInstance.get(
      `/bigquery/getTotalDesafiosAlunosDia`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          advisor: filters.currentAdvisor?.login,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getTotalFacilitatorsChallengesByDay(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator' | 'currentAdvisor'
    >
  ): Promise<Array<StudentsChallengesByDay>> {
    const { data }: AxiosResponse<Array<StudentsChallengesByDay>> = await axiosInstance.get(
      `/bigquery/getTotalDesafiosInstrutoresDia`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          facilitador: filters.currentFacilitator?.login,
          advisor: filters.currentAdvisor?.login,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getStudentsChallengesAverages(
    filters: FilterGeneralPanelLessonEvaluationParams
  ): Promise<Array<StudentChallengesInfoAndAveragesByDay>> {
    const { data }: AxiosResponse<Array<StudentChallengesInfoAndAveragesByDay>> = await axiosInstance.get(
      `/bigquery/getDesafiosAlunosDias`,
      {
        params: filters.dateInterval,
      }
    );

    return data;
  },
  async getFacilitatorsChallengesAverages(
    filters: FilterGeneralPanelLessonEvaluationParams
  ): Promise<Array<StudentChallengesInfoAndAveragesByDay>> {
    const { data }: AxiosResponse<Array<StudentChallengesInfoAndAveragesByDay>> = await axiosInstance.get(
      `/bigquery/getDesafiosInstrutoresDias`,
      {
        params: filters.dateInterval,
      }
    );

    return data;
  },
  async routineGetEmailsSuporte(): Promise<void> {
    const { data }: AxiosResponse<void> = await axiosInstance.get(`/bigquery/getEmailsSuporte`);

    return data;
  },
  async logPageView(payload: PageViewLogType): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('/bigquery/logPageView', payload);

    return data;
  },
  async logEventoLogin(payload: EventoLoginLogType): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('/bigquery/logEventoLogin', payload);

    return data;
  },
  async logEventoDeuPresenca(payload: EventoDeuPresencaLogType): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('/bigquery/logEventoDeuPresenca', payload);

    return data;
  },
  async logEventoFinalizouAutoestudo(payload: EventoFinalizouAutoestudoLogType): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('/bigquery/logEventoFinalizouAutoestudo', payload);

    return data;
  },
  async logEventoFinalizouAula(payload: EventoFinalizouAulaLogType): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('/bigquery/logEventoFinalizouAula', payload);

    return data;
  },
  async logEventoFinalizouConfigTurma(payload: EventoDeuPresencaLogType): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('/bigquery/logEventoFinalizouConfigTurma', payload);

    return data;
  },
  async logEventoFinalizouAvaliacaoProjeto(payload: EventoDeuPresencaLogType): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('/bigquery/logEventoFinalizouAvaliacaoProjeto', payload);

    return data;
  },
  async logEventoFinalizouMural(payload: EventoDeuPresencaLogType): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('/bigquery/logEventoFinalizouMural', payload);

    return data;
  },
  async logFinalizouLicaoAutoestudo(payload: FinalizouLicaoAutoestudoType): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('/bigquery/logFinalizouLicaoAutoestudo', payload);

    return data;
  },
  async getTempoAutoestudoPeriodo(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator' | 'currentAdvisor'
    >
  ): Promise<Array<TempoAgrupadoAutoestudo>> {
    const { data }: AxiosResponse<Array<TempoAgrupadoAutoestudo>> = await axiosInstance.get(
      '/bigquery/getTempoAutoestudoPeriodo',
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          facilitador: filters.currentFacilitator?.login,
          advisor: filters.currentAdvisor?.login,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getAcessosEscolaPaginasFiltradas(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'currentFacilitator' | 'filteredSchools'
    > & { limit?: number }
  ): Promise<Array<AcessoAgrupadoNome>> {
    const { data }: AxiosResponse<Array<AcessoAgrupadoNome>> = await axiosInstance.get(
      `/bigquery/getAcessosEscolaPaginasFiltradas`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          limit: filters.limit,
          idEscola: filters.currentSchool,
          currentFacilitator: filters.currentFacilitator?.login,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getRitmoAulas(filters: FilterRitmoAulasParam): Promise<Array<RitmoAula>> {
    const { data }: AxiosResponse<Array<RitmoAula>> = await axiosInstance.get(`/bigquery/getRitmoAulas`, {
      params: {
        dataInicio: filters.dateInterval.initialDate,
        dataFim: filters.dateInterval.finalDate,
      },
    });

    return data;
  },
  async getFechamentoAulas(filters: FilterFechamentoAulasParam): Promise<Array<FechamentoAula>> {
    const { data }: AxiosResponse<Array<FechamentoAula>> = await axiosInstance.get(`/bigquery/getFechamentoAulas`, {
      params: {
        dataInicio: filters.dateInterval.initialDate,
        dataFim: filters.dateInterval.finalDate,
        modulo: encodeURIComponent(filters.modulo),
      },
    });

    return data;
  },
  async getProvasCategorias(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ): Promise<Array<ProvaCategoria>> {
    const { data }: AxiosResponse<Array<ProvaCategoria>> = await axiosInstance.get(
      `/bigquery/getProvasCategoriasData`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getProvasQuestoes(filters: FilterProvasQuestoesParam): Promise<Array<ProvaQuestoes>> {
    const { data }: AxiosResponse<Array<ProvaQuestoes>> = await axiosInstance.get(`/bigquery/getProvasQuestoesData`, {
      params: {
        dataInicio: filters.dateInterval.initialDate,
        dataFim: filters.dateInterval.finalDate,
        idEscola: filters.currentSchool,
        filteredSchools: filters.filteredSchools?.join(','),
      },
    });

    return data;
  },
  async getLicaoTopicosCurriculares(): Promise<Array<LicaoTopicosCurriculares>> {
    const { data }: AxiosResponse<Array<LicaoTopicosCurriculares>> = await axiosInstance.get(
      `/bigquery/getLicaoTopicosCurriculares`
    );

    return data;
  },
  async getAlunosEscolaStatus(params: { escolaId: string; status: string }): Promise<Array<AlunosAtivosType>> {
    const { data } = await axiosInstance.get(`/bigquery/getAlunosEscolaStatus`, {
      params,
    });

    return data;
  },
  async getAvaliacoesLicoesPorAluno(
    filters: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>
  ): Promise<Array<LessonStudentEvaluationAverageByDay>> {
    const { data }: AxiosResponse<Array<LessonStudentEvaluationAverageByDay>> = await axiosInstance.get(
      `/bigquery/getAvaliacoesLicoesPorAluno`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
        },
      }
    );

    return data;
  },
  async getDetailedAvaliacoesLicoesAluno(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    >
  ): Promise<Array<LessonStudentEvaluation>> {
    const { data }: AxiosResponse<Array<LessonStudentEvaluation>> = await axiosInstance.get(
      `/bigquery/getDetailedAvaliacoesLicoesAluno`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          login: filters.currentFacilitator?.login,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getPeriodSchoolSummary(filters: FilterPeriodSchoolSummary): Promise<string> {
    const { data }: AxiosResponse<string> = await axiosInstance.get(
      `/bigquery/recuperarResumoAssessoriaEscolaPeriodo`,
      {
        params: {
          escolaId: filters.currentSchool,
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
        },
      }
    );

    return data;
  },
  async getOcorrencias(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'currentFacilitator' | 'filteredSchools'
    > & { incluirFechadas: boolean }
  ): Promise<Array<OcorrenciaTypeBigquery>> {
    const { data }: AxiosResponse<Array<OcorrenciaTypeBigquery>> = await axiosInstance.get(`/bigquery/getOcorrencias`, {
      params: {
        incluirFechadas: filters.incluirFechadas,
        dataInicio: filters.dateInterval.initialDate,
        dataFim: filters.dateInterval.finalDate,
        idEscola: filters.currentSchool,
        facilitador: filters.currentFacilitator?.login,
        filteredSchools: filters.filteredSchools?.join(','),
      },
    });

    return data;
  },
  async getOcorrenciasAbertasFiltradas(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'currentFacilitator' | 'filteredSchools'
    >
  ): Promise<Array<AcessoAgrupadoNome>> {
    const { data }: AxiosResponse<Array<AcessoAgrupadoNome>> = await axiosInstance.get(
      `/bigquery/getOcorrenciasAbertasFiltradas`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          facilitador: filters.currentFacilitator?.login,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
  async getOcorrenciasNaoFinalizadasFiltradas(
    filters: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'currentFacilitator' | 'filteredSchools'
    >
  ): Promise<Array<AcessoAgrupadoNome>> {
    const { data }: AxiosResponse<Array<AcessoAgrupadoNome>> = await axiosInstance.get(
      `/bigquery/getOcorrenciasNaoFinalizadasFiltradas`,
      {
        params: {
          dataInicio: filters.dateInterval.initialDate,
          dataFim: filters.dateInterval.finalDate,
          idEscola: filters.currentSchool,
          facilitador: filters.currentFacilitator?.login,
          filteredSchools: filters.filteredSchools?.join(','),
        },
      }
    );

    return data;
  },
};

export default BigQueryAPI;

import React from 'react';
import { RotateLoader } from 'react-spinners';

type LoadingRotateProps = { showing: boolean; size: number; margin: number };

const LoadingRotate = ({ showing, size, margin }: LoadingRotateProps) => {
  return (
    <RotateLoader
      loading={showing}
      color="#FF7900"
      cssOverride={{
        display: 'block',
        margin: '0 auto',
        borderColor: 'red',
      }}
      size={size}
      margin={margin}
    />
  );
};

export default LoadingRotate;

import { AxiosPromise, AxiosResponse } from 'axios';

import { axiosInstance } from 'lib/axios';

export type PlanoType = Pick<AulaAdaptadaDb, 'nomePlano' | 'descricaoPlano' | 'minutosAula' | 'status'> & {
  expansaoAulas: Record<AulaAdaptadaDb['codigoAula'], AulaAdaptadaDb & { cadernos?: boolean }>;
};

export type AulaAdaptadaPlanosType = Partial<Record<AulaAdaptadaDb['idPlano'], PlanoType>>;

type DadosCurso = {
  codigoCurso: CursoDb['codigo'];
  nomeCurso: CursoDb['nome'];
  modulosPermitidos: Array<{
    codigoCurso: CursoDb['codigo'];
    codigoModulo: string;
    nomeModulo: string;
    aulas: Array<{
      codigoCurso: CursoDb['codigo'];
      codigoModulo: string;
      codigoAula: string;
      nomeAula: string;
      unidade: number;
    }>;
  }>;
};

export type FundamentalActivity = {
  index: string;
  link: string;
  description: string;
  duration: number;
};

export const CursoService = {
  async list(): Promise<Array<CursoDb>> {
    const { data }: AxiosResponse<Array<CursoDb>> = await axiosInstance.get('cursos');

    return data;
  },
  async exists(id: string): Promise<AxiosPromise> {
    const { data } = await axiosInstance.get(`cursos/${id}/exists`);

    return data;
  },
  async findById(id: string): Promise<CursoDb> {
    const { data }: AxiosResponse<CursoDb> = await axiosInstance.get(`cursos/${id}`);

    return data;
  },
  async delete(id: string): Promise<AxiosPromise> {
    const { data } = await axiosInstance.delete(`cursos/${id}`);

    return data;
  },

  // TODO: validate
  async insert(payload: CursoDb): Promise<AxiosPromise> {
    const { data } = await axiosInstance.put('cursos/', payload);

    return data;
  },

  // TODO: validate
  async update(payload: CursoDb): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('cursos', payload);

    return data;
  },
  async listPublic(id: string): Promise<CursoDb> {
    const { data }: AxiosResponse<CursoDb> = await axiosInstance.get(`cursos/todosOsCursos/publico?codigoCurso=${id}`);

    return data;
  },
  async metadados(id: string): Promise<string | null> {
    const { data }: AxiosResponse<string | null> = await axiosInstance.get(`aulas/metadados/publico?codigo=${id}`);

    return data;
  },
  async buscarModuloNovaConsultaPublico(
    codigoCurso: string,
    codigoModulo: string
  ): Promise<BuscarModuloNovaConsultaPublicoType | null> {
    const { data }: AxiosResponse<BuscarModuloNovaConsultaPublicoType | null> = await axiosInstance.get(
      `cursos/buscarModuloNovaConsulta/publico?codigoCurso=${codigoCurso}&codigoModulo=${codigoModulo}`
    );

    return data;
  },

  async gerarBaseConhecimento(codigoCurso: string, codigoModulo: string): Promise<string> {
    const { data }: AxiosResponse<string> = await axiosInstance.get(
      `cursos/gerarBaseConhecimento?codigoCurso=${codigoCurso}&codigoModulo=${codigoModulo}`
    );

    return data;
  },

  async buscarPadroesCurricularesNovaConsulta(codigoCurso: string, codigoModulo: string): Promise<string[] | null> {
    const { data }: AxiosResponse<string[] | null> = await axiosInstance.get(
      `cursos/buscarPadroesCurricularesNovaConsulta/publico?codigoCurso=${codigoCurso}&codigoModulo=${codigoModulo}`
    );

    return data;
  },
  // TODO: validate return type
  async buscaPlanos(idEscola: string, curso: string, modulo: string): Promise<AulaAdaptadaPlanosType> {
    const { data }: AxiosResponse<AulaAdaptadaPlanosType> = await axiosInstance.get(
      `AulaAdaptada/buscaPlanos?idEscola=${idEscola}&curso=${curso}&modulo=${modulo}`
    );

    return data;
  },
  async recuperarModulosCursos(
    codigosCursos: string[]
  ): Promise<Array<Pick<CursoDb, 'codigo' | 'nome' | '_modulos' | 'duracaoTipo'>>> {
    const { data }: AxiosResponse<Array<Pick<CursoDb, 'codigo' | 'nome' | '_modulos' | 'duracaoTipo'>>> =
      await axiosInstance.get(`Cursos/recuperarModulosCursos?codigosCursos=${codigosCursos.join(',')}`);

    return data;
  },
  async recuperarAtividadesFundamentais(
    codigoCurso: string,
    codigoModulo: string
  ): Promise<Array<FundamentalActivity>> {
    const { data }: AxiosResponse<Array<FundamentalActivity>> = await axiosInstance.get(
      `Cursos/recuperarAtividadesFundamentais`,
      {
        params: {
          codigoCurso,
          codigoModulo,
        },
      }
    );

    return data;
  },
  async recuperaCursosAtivosEscola(escolaId: string, accessToken: string): Promise<Array<CursoDb>> {
    const { data }: AxiosResponse<Array<CursoDb>> = await axiosInstance.get(`Cursos/recuperarCursosAtivosEscola`, {
      params: {
        escolaId,
        accessToken,
      },
    });

    return data;
  },
  async recuperaCursosAtivos(login?: string) {
    const { data }: AxiosResponse<Array<CursoDb>> = await axiosInstance.get(`Cursos/recuperarCursosAtivos`, {
      params: login
        ? {
            login,
          }
        : undefined,
    });

    return data;
  },
  async divisaoAulasModulo(
    codigoCurso: string,
    codigoModulo: string
  ): Promise<
    Array<{
      curso: string;
      modulo: string;
      aulacodigo: string;
      aulanome: string | undefined;
      unidade: number;
    }>
  > {
    const { data } = await axiosInstance.get(
      `Cursos/divisaoAulasModulo?codigoCurso=${codigoCurso}&codigoModulo=${codigoModulo}`
    );

    return data;
  },
  async recuperarModulosCurso(
    codigoCurso: string,
    escolaId: string
  ): Promise<
    Array<{
      codigo: ModuloTypeDb['codigo'];
      nome: ModuloTypeDb['nome'];
    }>
  > {
    const { data } = await axiosInstance.get(
      `Cursos/recuperarModulosDoCurso?codigoCurso=${codigoCurso}&escolaId=${escolaId}`
    );

    return data;
  },
  async cursosPermitidos(escolaId: string): Promise<Array<DadosCurso>> {
    const { data } = await axiosInstance.get(`Cursos/cursosPermitidos?escolaId=${escolaId}`);

    return data;
  },
  async recuperarAutoEstudosPermitidos(
    accessToken: string,
    escolaId: string
  ): Promise<
    Array<{
      codigo: CursoDb['codigo'];
      nome: CursoDb['nome'];
      modulos: Array<Pick<ModuloTypeDb, 'codigo' | 'nome'>>;
    }>
  > {
    const { data } = await axiosInstance.get(
      `Cursos/recuperarAutoEstudosPermitidos?accessToken=${accessToken}&escolaId=${escolaId}`
    );

    return data;
  },
};

export const REFERENCIAL_PEDAGOGICO = 'c8c392e0-0ed4-11ee-a502-afb18e8fd207' as const;
export const PLATAFORMA_MM_MINI_EAD = 'c9be1f80-0ed4-11ee-a502-afb18e8fd207' as const;
export const PLATAFORMA_MM_SIMULADORES = 'c96aa850-0ed4-11ee-a502-afb18e8fd207' as const;
export const PLATAFORMA_MM_LABORATORIOS = 'c98646a0-0ed4-11ee-a502-afb18e8fd207' as const;
export const PLATAFORMA_MM_JOGOS = 'c9a23310-0ed4-11ee-a502-afb18e8fd207' as const;
export const PLATAFORMA_MM_CORE = 'c94ebbe0-0ed4-11ee-a502-afb18e8fd207' as const;
export const KITS_TECNOLOGICOS = 'c9dc2ed0-0ed4-11ee-a502-afb18e8fd207' as const;
export const AMBIENTES_EXTERNOS = 'c9f92cb0-0ed4-11ee-a502-afb18e8fd207' as const;

export const KNOWLEDGE_BASE_CURSO_KIDS = 'c8e069b0-0ed4-11ee-a502-afb18e8fd207';
export const KNOWLEDGE_BASE_CURSO_MINI = 'c932f680-0ed4-11ee-a502-afb18e8fd207';
export const KNOWLEDGE_BASE_CURSO_TEENS = 'c8fbe0f0-0ed4-11ee-a502-afb18e8fd207';
export const KNOWLEDGE_BASE_CURSO_YOUNG = 'c9175830-0ed4-11ee-a502-afb18e8fd207';

import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';

import { queryClient } from 'lib/react-query';

import useEventListener from 'hooks/useEventListener';
import errorTracking from 'util/errorTracking';

import Loading from 'components/Loading';
import ScrollToTopOnNewPage from 'routes/ScrollToTopOnNewPage';
import ErrorFallBack from 'pages/ErrorFallBack';
import Chatbot from 'components/Chatbot';
import { ChatbotProvider } from './ChatbotContextProvider';
import useReadLocalStorage from 'hooks/useReadLocalStorage';
import { createTheme, ThemeProvider } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ShepherdJourneyProvider } from 'react-shepherd';
import { AuthProvider } from './AuthProvider';
// import { RouterProvider } from '@tanstack/react-router';
// import { router } from 'routes';

type AppProviderProps = {
  children: React.ReactNode;
};

// TODO - Adicionar aqui AuthProvider
// TODO - Adicionar aqui Notification provider...

declare module '@mui/material/styles' {
  interface DefaultTheme extends Theme {}
}

const defaultTheme = createTheme();

export const AppProvider = ({ children }: AppProviderProps) => {
  const firstcodeUserProfile = useReadLocalStorage<FirstcodeUserProfile>('firstcodeUserProfile');

  const [showChatGPTChatbot, setShowChatGPTChatbot] = useState(false);

  useEffect(() => {
    setShowChatGPTChatbot((prev) => {
      if (firstcodeUserProfile?.username === 'instrutorf1' || firstcodeUserProfile?.username === 'instrutorf2') {
        return true;
      }

      return prev;
    });
  }, [setShowChatGPTChatbot, firstcodeUserProfile?.username]);

  const redirectToPageOnReset = () => {
    if (firstcodeUserProfile?.isAuthenticated) {
      window.location.assign(
        `${window.location.origin}/${
          firstcodeUserProfile?.homepage === 'school' ? 'escola' : firstcodeUserProfile?.homepage || ''
        }`
      );
    } else {
      console.warn('is not authed', { homepage: firstcodeUserProfile?.homepage });
      window.location.assign(window.location.origin);
    }
  };

  useEventListener('keyup', (evt) => {
    setShowChatGPTChatbot((prevShow) => {
      if (!evt.ctrlKey || !evt.shiftKey || evt.key !== 'Z') {
        return prevShow;
      }

      return !prevShow;
    });
  });

  return (
    <React.Suspense fallback={<Loading />}>
      <ErrorBoundary FallbackComponent={ErrorFallBack} onError={errorTracking} onReset={() => redirectToPageOnReset()}>
        <AuthProvider>
          <ThemeProvider theme={defaultTheme}>
            <ShepherdJourneyProvider>
              <ChatbotProvider>
                <QueryClientProvider client={queryClient}>
                  {/* <RouterProvider router={router} /> */}
                  <BrowserRouter>
                    <ScrollToTopOnNewPage />
                    {children}
                    {firstcodeUserProfile?.isAuthenticated &&
                      showChatGPTChatbot &&
                      firstcodeUserProfile.tipo !== 'aluno' && <Chatbot />}
                  </BrowserRouter>
                  {!import.meta.env.PROD && <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />}
                </QueryClientProvider>
              </ChatbotProvider>
            </ShepherdJourneyProvider>
          </ThemeProvider>
        </AuthProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};

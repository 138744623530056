import { useState, useEffect } from 'react';
import useMediaQuery from './useMediaQuery';

const useIsMobile = () => {
  const isMobileByDeviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/gi.test(
    navigator.userAgent
  );
  const isMobileByDeviceMaxWidth = useMediaQuery('(max-width: 767px)');

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileByDeviceType || isMobileByDeviceMaxWidth);
  }, [isMobileByDeviceType, isMobileByDeviceMaxWidth]);

  return isMobile;
};

export default useIsMobile;

import React from 'react';

import { useContext, createContext, useCallback } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import useReadLocalStorage from '../hooks/useReadLocalStorage';
import useEventListener from '../hooks/useEventListener';

export type ChatbotContextData = {
  currentLesson?: {
    codigoAula: string;
    codigoModulo: string;
    codigoCurso: string;
    nomeAula: string;
    unidade: number;
    planoSelecionado?: string;
  };
  currentModule?: {
    codigoModulo: string;
    codigoCurso: string;
    nomeModulo: string;
  };
  currentCourse?: {
    codigoCurso: string;
    nomeCurso: string;
  };
  currentKnowledgeBase?: {
    id: string;
    topic?: string;
  };
  selectedSubject: string;
  specificDivisionFromSubject?: string;
};

export const CHATBOT_CONTEXT_LOCAL_STORAGE_KEY = `chatbot_context`;

export const useUpdateChatbotContext = (params?: { clearOnPageLeave: Array<keyof ChatbotContextData> }) => {
  const [contextData, setChatbotContext] = useLocalStorage<ChatbotContextData>(CHATBOT_CONTEXT_LOCAL_STORAGE_KEY, {
    selectedSubject: '',
  });

  const updateChatbotContext = useCallback(
    (newContext: Partial<ChatbotContextData>) =>
      setChatbotContext((prevContext) => ({ ...prevContext, ...newContext })),
    [setChatbotContext]
  );

  useEventListener('beforeunload', () => {
    if (params?.clearOnPageLeave) {
      const newContext = { ...contextData };

      for (const key of params.clearOnPageLeave) {
        delete newContext[key];
      }

      updateChatbotContext(newContext);
    }
  });

  return updateChatbotContext;
};

const ChatbotContext = createContext<ChatbotContextData>({
  selectedSubject: '',
});

export const useChatbotContext = () => {
  const context = useContext(ChatbotContext);

  if (!context) throw new Error('useChatbotContext must be used within a ChatbotProvider');

  return context;
};

type ChatbotProviderProps = {
  children: React.ReactNode;
};

export const ChatbotProvider = ({ children }: ChatbotProviderProps) => {
  const chatbotContextData =
    useReadLocalStorage<ChatbotContextData>(CHATBOT_CONTEXT_LOCAL_STORAGE_KEY) || ({} as ChatbotContextData);

  return <ChatbotContext.Provider value={chatbotContextData}>{children}</ChatbotContext.Provider>;
};

import { Dispatch, SetStateAction, useState, useCallback, useEffect } from 'react';

import useEventListener from './useEventListener';

import SessionStorage from 'util/session-storage';
import { parseJSON } from 'util/parseJSON';

declare global {
  interface WindowEventMap {
    'session-storage': CustomEvent;
  }
}

export type SetToSessionStorageType<T> = Dispatch<SetStateAction<T>>;

const useSessionStorage = <T>(key: string, initialValue: T): [T, SetToSessionStorageType<T>] => {
  const readValueFromSessionStorage = useCallback(() => {
    if (typeof window === 'undefined') return initialValue;

    try {
      const item = SessionStorage.get(key);

      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading '${key}' from sessionStorage: `, error);

      return initialValue;
    }
  }, [key, initialValue]);

  const [storedValue, setStoredValue] = useState<T>(readValueFromSessionStorage);

  const setSessionStorageValue: SetToSessionStorageType<T> = useCallback(
    (value: T | ((arg0: T) => T)) => {
      if (typeof window === 'undefined')
        console.warn(`Tried setting sessionStorage key "${key}" even though environment is not a client`);

      try {
        const newValue = value instanceof Function ? value(storedValue) : value;

        SessionStorage.set(key, typeof newValue === 'string' ? newValue : JSON.stringify(newValue));

        setStoredValue(newValue);

        // Dispatch custom event so that every useLocalStorage hook are notified
        window.dispatchEvent(new Event('session-storage'));
      } catch (error) {
        console.warn(`Error setting sessionStorage key "${key}": `, error);
      }
    },
    [key, storedValue]
  );

  useEffect(() => {
    setStoredValue(readValueFromSessionStorage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStorageChange = useCallback(() => {
    setStoredValue(readValueFromSessionStorage());
  }, [readValueFromSessionStorage]);

  useEventListener('storage', handleStorageChange);

  useEventListener('session-storage', handleStorageChange);

  return [storedValue, setSessionStorageValue];
};

export default useSessionStorage;

import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Container, Grid, Paper, Typography } from '@mui/material';

const PREFIX = 'ErrorFallBack';

const classes = {
  container: `${PREFIX}-container`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.container}`]: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
}));

type ErrorFallBackProps = {
  resetErrorBoundary: () => void;
};

export default function ErrorFallBack({
  resetErrorBoundary = () => window.location.assign(window.location.origin),
}: ErrorFallBackProps) {
  return (
    <StyledContainer className={classes.container} disableGutters>
      <Paper variant="outlined">
        <Container>
          <Grid>
            <div role="alert">
              <Typography gutterBottom variant="h4">
                Ooops, aconteceu um erro :(
              </Typography>

              <Grid container justifyContent="flex-end">
                <Button variant="contained" color="primary" onClick={resetErrorBoundary}>
                  Voltar para o Início
                </Button>
              </Grid>
            </div>
            <br />
          </Grid>
        </Container>
      </Paper>
    </StyledContainer>
  );
}

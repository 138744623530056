import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import LoginRotate from './LoadingRotate';

const PREFIX = 'Loading';

const classes = {
  FullApp: `${PREFIX}-FullApp`
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.FullApp}`]: {
    height: '100vh',
  }
}));

const Loading = () => {


  return (
    <StyledGrid container justifyContent="center" alignItems="center" className={classes.FullApp}>
      <LoginRotate showing size={12} margin={3} />
    </StyledGrid>
  );
};

type LoadingAdjustableSizeProps = {
  width: string,
  height: string,
}

export const LoadingAdjustableSize = ({width, height}: LoadingAdjustableSizeProps) => {
  return (
    <StyledGrid container justifyContent="center" alignItems="center" className={classes.FullApp} style={{width: width, height: height}}>
      <LoginRotate showing size={12} margin={3} />
    </StyledGrid>
  );
};

export default Loading;

// Configuração geral cliente react-query
// pode gerar facilmente padroes para todo app

import { AxiosError } from 'axios';
import { QueryClient, UseQueryOptions, UseMutationOptions, DefaultOptions } from '@tanstack/react-query';
import { PromiseValue } from 'type-fest';

// Opções globais TODO s
// Definir se refetchOnWindowFocus é bom somente para certas queries? e tornar o padrão off
// Definir se aumentar cacheTime e staleTime são boas hipoteses.

const queryConfig: DefaultOptions = {
  queries: {
    throwOnError: true,
    refetchOnWindowFocus: false,
    gcTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 5,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type QueryConfig<FetcherFnType extends (...args: any) => any> = UseQueryOptions<
  PromiseValue<ReturnType<FetcherFnType>>
>;

export type MutationConfig<FetcherFnType extends (...args: any) => any> = UseMutationOptions<
  PromiseValue<ReturnType<FetcherFnType>>,
  AxiosError,
  Parameters<FetcherFnType>[0]
>;

import LogSistemaService from 'services/api/LogSistema';
import LocalStorage from 'util/local-storage';

const errorTracking = (error: Error, info: { componentStack: string }): void => {
  // Do something with the error
  // E.g. log to an error logging client here

  // eslint-disable-next-line no-console
  console.log('ErrorBoundary - componentDidCatch: errorTracking! ');

  // eslint-disable-next-line no-console
  console.error({ error, info });

  if (!import.meta.env.DEV) {
    try {
      const profileStr = LocalStorage.get('firstcodeUserProfile');

      if (profileStr) {
        const profile = JSON.parse(profileStr) as FirstcodeUserProfile;

        const payload = {
          login: profile.username,
          tipo: profile.tipo,
          error,
          info,
          accessToken: profile.accessToken,
        };

        LogSistemaService.createLog(payload);
      }
    } catch (err) {
      console.error({ err });
    }
  }
};

export default errorTracking;

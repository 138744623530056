import { isValidUUID } from './uuidTester';

export function parseJSON<T>(value: string | null): T | string | undefined {
  try {
    if (value !== null && isValidUUID(value) && !value.includes('"')) {
      return value; // from previous versions of useLocalStorage, where string where not properly treated
    }

    if (value === 'undefined') return undefined;

    if (value === 'en') return JSON.parse('"en"');

    if (value === 'pt-br') return JSON.parse('"pt-BR"');

    return JSON.parse(value ?? '');
  } catch (error) {
    console.warn(`Parsing error on "${value}"`, { error });

    return undefined;
  }
}

import axios, { AxiosResponse } from 'axios';

import type { ChatbotContextData } from 'contexts/ChatbotContextProvider';

const axiosInstance = axios.create({
  responseType: 'json',
});

type MessageUserRole = 'user' | 'assistant' | 'system';

export type ImageData = {
  base64: string | ArrayBuffer | null;
  extension: string;
};

export type NewMessageData = {
  role: MessageUserRole;
  content: string;
  datahora: string;
  imageUrl?: string;
};

export type MessageFeedback = {
  status?: 'liked' | 'disliked';
  observation?: string | null;
  datahora: string;
};

export type Message =
  | {
      readonly docId: string;
      role: Exclude<MessageUserRole, 'assistant'>;
      content: string;
      datahora: string;
      imageUrl?: string;
    }
  | {
      readonly docId: string;
      role: 'assistant';
      content: string;
      datahora: string;
      feedback?: MessageFeedback;
    };

export type ConversationIdentifier = {
  user: string;
  subject: string;
  specificDivisionFromSubject?: string;
};

type MessageIdentifier = ConversationIdentifier & { readonly docId: string };

export const ChatbotAPI = {
  async getConversation({
    user,
    subject,
    specificDivisionFromSubject,
  }: ConversationIdentifier): Promise<Array<Message>> {
    const { data }: AxiosResponse<Array<Message>> = await axiosInstance.get(
      `/firestore/conversations?user=${user}&subject=${subject}${
        !!specificDivisionFromSubject ? '&specificDivisionFromSubject=' + specificDivisionFromSubject : ''
      }`
    );

    return data;
  },
  async addMessageToConversation(
    conversationIdentifierWithNewMessage: ConversationIdentifier & {
      message: NewMessageData;
    }
  ): Promise<AxiosResponse> {
    const { data } = await axiosInstance.post(
      `/firestore/conversations/add_message`,
      conversationIdentifierWithNewMessage
    );

    return data;
  },
  async updateMessageFeedback(
    messageIdentifierWithFeedback: MessageIdentifier & {
      feedback: MessageFeedback;
    }
  ): Promise<MessageFeedback> {
    const { data } = await axiosInstance.post(
      `/firestore/conversations/update_message_feedback`,
      messageIdentifierWithFeedback
    );

    return data;
  },
  async clearConversation(conversationIdentifier: ConversationIdentifier): Promise<AxiosResponse> {
    const { data } = await axiosInstance.post(`/firestore/conversations/clear`, conversationIdentifier);

    return data;
  },
  async getChatbotResponse(
    context: ChatbotContextData & {
      currentUserType: string;
      conversationHistoryOnSubject: Array<Message> | undefined;
    }
  ): Promise<string> {
    const { data }: AxiosResponse<string> = await axiosInstance.post(`/chatgpt/prompt`, {
      context,
    });

    return data;
  },
  async uploadImage(image: ImageData): Promise<string> {
    const { data }: AxiosResponse<string> = await axiosInstance.post(`/firestore/conversations/upload_image`, {
      image,
    });

    return data;
  },
  async deleteImage(imageUrl: string): Promise<AxiosResponse> {
    const { data } = await axiosInstance.post(`/firestore/conversations/delete_image`, { imageUrl });

    return data;
  },
};

export default function getBaseConhecimentoInventoteca(idBaseConhecimento: string, topico: string) {
  if (idBaseConhecimento === 'c8c392e0-0ed4-11ee-a502-afb18e8fd207') {
    // Referencial Pedagógico
    // Faltando (?) 'RP06. Técnicas de Engajamento'
    // Faltando (?) 'RP12. Maximizando os Resultados'

    if (topico === 'Introdução') return '2bd64330-dec1-11ed-a960-17ed6297f642';
    else if (topico === 'Estrutura Curricular') return '0275f520-dec2-11ed-8bee-1509a9ba9073';
    else if (topico === 'Projetos') return '375e0150-dec3-11ed-b73b-833b8933773f';
    else if (topico === 'Método de Aprendizagem') return '5d71c700-dec3-11ed-a041-7365567446d0';
    else if (topico === 'Gamificação') return '8db56de0-dec3-11ed-a177-57b686be50e7';
    else if (topico === 'Recursos Didáticos') return 'c0b1cc20-dec3-11ed-a03c-d3e78537a7cc';
    else if (topico === 'Objetivos de Aprendizagem') return 'e54162d0-dec3-11ed-99c7-5b4131e8a227';
    else if (topico === 'Avaliação da Aprendizagem') return '07493d30-dec4-11ed-a1b1-1124432de26d';
    else if (topico === 'Planejamento da Implementação') return '1d6332b0-dec4-11ed-a666-8fc074776583';
    else if (topico === 'Implantação') return '37e675c0-dec4-11ed-85e9-43a7cdf5315a';
  } else if (idBaseConhecimento === 'c9dc2ed0-0ed4-11ee-a502-afb18e8fd207') {
    // Dispositivos Computacionais

    if (topico === 'Bee-Bot') return '23a24930-e2cb-11ed-a1f3-0399a19e94dd';
    else if (topico === 'Sphero Indi') return 'c237d1f0-e2cb-11ed-a1f3-0399a19e94dd';
    else if (topico === 'Sphero (Sprk, Bolt, Mini)') return '63490910-e2cc-11ed-9b00-3b2d7fcdeecd';
    else if (topico === 'littleBits') return '06212810-e2ce-11ed-b8c1-cb0eae693833';
    else if (topico === 'Makey Makey') return '755b2660-e2d6-11ed-86ee-837676daaadc';
    else if (topico === 'mBot') return 'e3d9e4a0-e2d6-11ed-86ee-837676daaadc';
    else if (topico === 'micro:bit') return '547149b0-e2d7-11ed-8264-e70fb39c59a2';
    else if (topico === 'Smartphone') return '96d99000-e2d7-11ed-b345-1967eed3e33b';
    else if (topico === 'Arduino') return 'b9cab8f0-e2d7-11ed-b3d9-09d945321634';
    else if (topico === 'Raspberry Pi – Circuito') return 'd21486c0-e2d7-11ed-a830-3ff7b81082c2';
    else if (topico === 'Raspberry Pi - Desktop') return 'f2cf20a0-e2d7-11ed-a830-3ff7b81082c2';
    else if (topico === 'Smart Lamp') return '133b9a80-e2d8-11ed-9c0d-590b3c1997b4';
  } else if (idBaseConhecimento === 'c9f92cb0-0ed4-11ee-a502-afb18e8fd207') {
    // Ambiente Externos (Sist. Computacionais Externos)

    if (topico === 'Code.org') return '2cac1850-e5e9-11ed-9ad9-7799d3da1c11';
    else if (topico === 'ML4Kids') return '5ea97d20-e5e9-11ed-bf87-0d9ebc8b37f1';
    else if (topico === 'NetsBlox') return '6f0ee060-e5e9-11ed-acfc-2b27dceb36a7';
    else if (topico === 'Piskel') return '54ee56c0-e5e9-11ed-ba63-a3e12b2cc949';
    else if (topico === 'Scratch') return '3c7d78f0-e5e9-11ed-863c-b37d857c3150';
  } else if (idBaseConhecimento === 'c9a23310-0ed4-11ee-a502-afb18e8fd207') {
    // Plataforma MM - Jogos (Sist. Computacionais Próprios (????))
    // Ver mais detalhes possivelmente faltantes aqui

    if (topico === 'Global Trade') return 'e71a0950-e5e8-11ed-8570-13d982828db0';
  }
}

import { AxiosPromise, AxiosResponse } from 'axios';

import { axiosInstance } from 'lib/axios';
import type { Person, Student } from 'services/external/PlurallAPI';

export interface SchoolInfoPlurall {
  loginAdminSchool: string;
  escolaId: string;
}

export interface PlurallUser {
  username: string;
  tipo: string;
  nome: string;
  ssoidentificador: string;
  ssotype: string;
  turma: AlunoDb['ultimaTurmaMatriculado'];
}

type UserBasicData = Pick<UserDb, 'id' | 'nome'>;

type StudentsFromResponsible = Array<{
  id: AlunoDb['login'];
  alunoNome: AlunoDb['nome'];
  tipoUsuario: 'aluno';
  nome: ResponsavelDb['resp1Nome'] | ResponsavelDb['resp2Nome'];
}> | null;

export type PublicUser =
  | [UserBasicData & { tipoUsuario: 'escola' }]
  | [UserBasicData & { tipoUsuario: 'instrutor'; meusAlunos: Record<string, string> }]
  | StudentsFromResponsible;

type UsersAndSchoolsNames = {
  nomesEscolas: Record<string, EscolaDb['nome']>;
  nomesUsuarios: Record<string, UserDb['nome']>;
};

const UserServices = {
  async list(): Promise<Array<UserDb>> {
    const { data }: AxiosResponse<Array<UserDb>> = await axiosInstance.get('Users');
    return data;
  },
  async exists(id: string): Promise<boolean> {
    let method = `Users/${id}/exists`;

    if (id.includes('/')) {
      method = `Users/existsByIdWithInvalidCharacters/?id=${encodeURIComponent(id)}`;
    }

    const { data } = await axiosInstance.get(method);

    if (typeof data === 'boolean') {
      return data;
    }

    if (data.hasOwnProperty('exists')) {
      return data.exists;
    }

    return false;
  },
  async findById(id: string): Promise<UserDb> {
    let method = `Users/${id}`;

    if (id.includes('/')) method = `Users/findByIdWithInvalidCharacters?id=${id}`;

    const { data }: AxiosResponse<UserDb> = await axiosInstance.get(method);
    return data;
  },
  async delete(id: string): Promise<AxiosPromise> {
    const { data } = await axiosInstance.delete(`Users/${id}`);
    return data;
  },
  async insert(payload: UserDb): Promise<AxiosPromise> {
    const { data } = await axiosInstance.put('Users/', payload);
    return data;
  },
  async update(payload: UserDb): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('Users', payload);
    return data;
  },
  async findByEmail(email: string): Promise<UserDb> {
    const { data }: AxiosResponse<UserDb> = await axiosInstance.get(`Users/findByEmail?email=${email}`);
    return data;
  },
  async recuperarUsuario(email: string): Promise<UserDb> {
    const { data }: AxiosResponse<UserDb> = await axiosInstance.get(`Users/recuperarUsuario?email=${email}`);
    return data;
  },
  async recuperarUsuarioPublico(email: string): Promise<PublicUser> {
    const { data }: AxiosResponse<PublicUser> = await axiosInstance.get(
      `Users/recuperarUsuario/publico?email=${email}`
    );
    return data;
  },
  async getUsuariosComNomeEscola(logins: string[]): Promise<Array<Pick<UserDb, 'id' | 'nome' | 'escolaNome'>>> {
    const { data }: AxiosResponse<Array<Pick<UserDb, 'id' | 'nome' | 'escolaNome'>>> = await axiosInstance.get(
      `Users/usuariosComNomeEscola`,
      {
        params: {
          loginsList: encodeURIComponent(logins.join(',')),
        },
      }
    );
    return data;
  },
  async getArrayByPlurallId(
    payload: Array<Person> | Array<Student>,
    schoolinfo: SchoolInfoPlurall
  ): Promise<Array<PlurallUser>> {
    const { data } = await axiosInstance.post('Users/getArrayByPlurallId', { users: payload, schoolinfo });
    return data;
  },
  async recuperarNomesUsuariosEscolas(usersAndSchools: {
    escolas?: string[];
    usuarios: string[];
  }): Promise<UsersAndSchoolsNames> {
    const { data }: AxiosResponse<UsersAndSchoolsNames> = await axiosInstance.post(
      'Users/recuperarNomesEscolasUsuariosLogs',
      {
        usersAndSchools,
      }
    );

    return data;
  },
};

export default UserServices;


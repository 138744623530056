import { AxiosPromise, AxiosResponse } from 'axios';

import { axiosInstance } from 'lib/axios';
import { AlunosAtivosType } from './EscolasService';

export interface FindFotoPresencaReturnType {
  id: string;
  matriculaId: string;
  aulaCodigo: string;
  alunoDescricao: string;
  alunoLogin: string;
  alunoNome: string;
  avalAula: string | null;
  avalInstrutor: string | null;
  cancelarEmailResponsavel: boolean;
  curso: string;
  data: string;
  dataAtualizacao: string;
  dataCriacao: string;
  dataFimLicao: string | null;
  escolaId: string;
  foto: string;
  fotoOriginalUrl: string;
  fotoUrl: string;
  indPresente: boolean;
  indenvioemail: string;
  justificativa: string | null;
  login: string;
  modulo: string;
  missaoVerde: boolean | null;
  missaoVermelho: boolean | null;
  missaoAzul: boolean | null;
  nome: string;
  sexo: string;
  turmaId: string;
  turmaNome: string;
  unidade: number;
  usuarioAtualizacao: string;
  usuarioCriacao: string;
}

interface Aulasobj extends AulaagendaTypeDb {
  aulaCodigo: string;
  nomeAula: string;
  status: 'NAO_INICIADA' | 'EM_ANDAMENTO' | 'ENCERRADA';
  ead: boolean | null;
  datahorainicio: string | null;
  datahorafim: string | null;
}

interface AlunoAulasType {
  codigoCurso: string;
  codigoModulo: string;
  turmaId: string;
  aulas: Array<Aulasobj>;
}

interface AlunoCursotype {
  regular: CursoDb | null;
  max: CursoDb | null;
}

interface AlunoProjetos {
  id: ProjetoDb['id'];
  titulo: ProjetoDb['titulo'];
  data: ProjetoDb['data'];
  liderequipe: ProjetoDb['liderEquipe'];
  liderequipe2: ProjetoDb['liderEquipe2'];
  status: ProjetoDb['status'];
  nomeproduto: ProjetoDb['nomeProduto'];
}

interface ProgressoAtual {
  mediaObtida: number;
  nomeModulo: string;
  totalObtido: number;
  totalPossivel: number;
}
interface ModuloAtualPonto {
  total: number;
  obtido: number;
}
interface ResumoPontos {
  moduloAtual: ModuloAtualPonto;
  geral: ModuloAtualPonto;
}
export interface AlunoProgresso {
  bloquearAlteracaoFoto: boolean | null;
  checklistPonto: Array<ChecklistpontoTypeDb> | null;
  condecoracao: Array<CondecoracaoTypeDb> | null;
  escolaId: string;
  fotoUrl: string | null;
  login: string;
  moduloAtual: string;
  nome: string;
  progressoAtual: ProgressoAtual;
  progressoGeral: number;
  resumoPontos: ResumoPontos;
  ssoidentificador: string | null;
  ssotype: string | null;
  turmas: TurmasMap | null;
  ultimaTurmaMatriculado: string | null;
}

export type AlunoDbInsert = AlunoDb &
  Partial<{
    loginAdminSchool: string;
    username: string;
    tipo: string;
    accessToken: string;
    ssotype: string;
    ssoidentificador: string;
  }>;

interface ProgramaType {
  nome: string;
  url: string;
  compartilhar: boolean;
  tipoItem: string;
  urilogo: string;
}

export type StudentBasicData = Pick<AlunoDb, 'login' | 'nome' | 'pwd' | 'fotoUrl' | 'fotoOriginalUrl'>;

// TODO: validate
export type AlunoEleicaoType = EleicaoDb & {
  praticaDados: PraticaDb | undefined;
  projetos: Array<ProjetoDb>;
  aluno: AlunoDb;
};

interface AlunoValidarStatusType {
  response: Array<AlunoDb>;
  countFix: number;
  countSkip: number;
}

interface DadosFotoEStatusLiderAlunoType {
  fotoUrl: AlunoDb['fotoUrl'] | AlunoDb['fotoOriginalUrl'];
  jaFoiLider: AlunoDb['jaFoiLider'];
}

export type AlunoInfo = {
  login: string;
  turmaId: AlunoDb['ultimaTurmaMatriculado'] | null;
  turma: string | undefined | null;
  modulo: string | null;
  modalidade: TurmaDb['modoTurma'] | null;
  ultimaLicaoAbertaFacilitador: AtividadeexecucaoTypeDb['aulacodigo'] | AulaCorrenteDb['aulaCodigo'] | null;
  ultimaAtividadeExecutadaFacilitador: AtividadeexecucaoTypeDb['aulacodigo'] | AulaCorrenteDb['atividadeOrdem'] | null;
  projetos: Array<{
    id: ProjetoDb['id'];
    nome: ProjetoDb['titulo'];
    equipeNome: ProjetoDb['nomeProduto'];
    equipeLider: ProjetoDb['liderEquipe'];
    equipeAlunos: Array<{ login: string; nome: string }>;
  }>;
};

type DesafioAmbienteAluno = {
  codigo: string;
  nome?: string;
  fases: Array<{
    codigo: string;
    nome?: string;
    unidade: number;
    desafio?: {
      id: CodigoDb['id'];
      nome: CodigoDb['nome'];
      questoes: Array<{
        key: number;
        status: 'NOT_DONE' | 'DONE_WRONG' | 'DONE_RIGHT_WITH_TOO_MANY_COMMANDS' | 'DONE_RIGHT_WITH_MINIMAL_COMMANDS';
        tempoGastoSegs: number;
      }>;
      totalTempoGastoSegs?: number;
    };
  }>;
};

// TODO - validar que estão coretos e fazer types.
const Alunoservices = {
  async list(): Promise<Array<AlunoDb>> {
    const { data }: AxiosResponse<Array<AlunoDb>> = await axiosInstance.get('Alunos');

    return data;
  },
  async exists(id: string): Promise<boolean> {
    let method = `Alunos/${id}/exists`;

    if (id.includes('/')) {
      method = `Alunos/existsByIdWithInvalidCharacters/?id=${encodeURIComponent(id)}`;
    }

    const { data } = await axiosInstance.get(method);

    if (typeof data === 'boolean') {
      return data;
    }

    if (data.hasOwnProperty('exists')) {
      return data.exists;
    }

    return false;
  },
  async findById(id: string): Promise<AlunoDb> {
    let method = 'Alunos/' + id;

    if (id.includes('/')) method = 'Alunos/findByIdWithInvalidCharacters?id=' + id;

    const { data } = await axiosInstance.get(method);

    return data;
  },
  async delete(id: string): Promise<AxiosPromise> {
    const { data } = await axiosInstance.delete(`Alunos/${id}`);

    return data;
  },
  async insert(payload: AlunoDbInsert): Promise<AxiosPromise> {
    const { data } = await axiosInstance.put('Alunos/', payload);

    return data;
  },
  async update(payload: AlunoDb): Promise<AlunoDb> {
    const { data } = await axiosInstance.post('Alunos', payload);

    return data;
  },
  async eu(): Promise<AlunoEuObject> {
    const { data }: AxiosResponse<AlunoEuObject> = await axiosInstance.get('Alunos/eu');

    return data;
  },
  async aulas(): Promise<AlunoAulasType> {
    const { data }: AxiosResponse<AlunoAulasType> = await axiosInstance.get('Alunos/aulas');

    return data;
  },
  async meuCurso(): Promise<AlunoCursotype> {
    const { data }: AxiosResponse<AlunoCursotype> = await axiosInstance.get('Alunos/curso');

    return data;
  },
  async projetos(): Promise<Array<AlunoProjetos>> {
    const { data }: AxiosResponse<Array<AlunoProjetos>> = await axiosInstance.get('Alunos/projetos');

    return data;
  },
  async eleicoes(): Promise<AlunoEleicaoType> {
    const { data }: AxiosResponse<AlunoEleicaoType> = await axiosInstance.get('Alunos/eleicoes');

    return data;
  },
  async progresso(loginAluno: string): Promise<AlunoProgresso> {
    let method = 'Alunos/progresso/' + loginAluno;

    if (loginAluno.includes('/')) method = 'Alunos/progressoByIdWithInvalidCharacters?loginDoAluno=' + loginAluno;

    const { data }: AxiosResponse<AlunoProgresso> = await axiosInstance.get(method);

    return data;
  },
  async ultimaPresenca(loginAluno: string): Promise<Array<PresencaDb>> {
    const { data } = await axiosInstance.get(`Alunos/ultimaPresenca?alunoLogin=${loginAluno}`);

    return data;
  },
  async portifolio(loginAluno: string): Promise<AlunoDb['portifolio']> {
    // TODO: validar tipo
    let method = 'Alunos/portifolio/' + loginAluno;

    if (loginAluno.includes('/')) method = 'Alunos/portifolioByIdWithInvalidCharacters?id=' + loginAluno;

    const { data }: AxiosResponse<AlunoDb> = await axiosInstance.get(method);

    return data.portifolio;
  },
  async votacaoAulaDBAluno(): Promise<PresencaDb | []> {
    const { data }: AxiosResponse<PresencaDb | []> = await axiosInstance.get('Alunos/votacaoAulaDBAluno');

    return data;
  },
  async publicarProgramaPortfolio(payload: ProgramaType): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('Alunos/publicarProgramaPortfolio', payload);

    return data;
  },
  async listarPorResponsavel(responsavelId: string): Promise<Array<AlunoDb>> {
    const { data }: AxiosResponse<Array<AlunoDb>> = await axiosInstance.get(`Alunos/listarPorResponsavel`, {
      params: {
        responsavelId,
      },
    });

    return data;
  },
  async importarAlunoResponsavelPublico(
    campos: string[],
    prefixoLogin: string,
    usuario: string,
    accessToken: string,
    escolaId: string
  ): Promise<AlunoDb> {
    const { data } = await axiosInstance.post('Alunos/importarAlunoResponsavel/publico', {
      campos,
      prefixoLogin,
      usuario,
      accessToken,
      escolaId,
    });

    return data;
  },
  // TODO: validate response
  async reiniciarSenha(payload: { login: string; accessToken: string }): Promise<string> {
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const { data } = await axiosInstance.post(`Alunos/reiniciarSenha`, { payload }, { headers });

    return data;
  },
  // TODO: validate response
  async importarFotoAluno(imagemBase64: string, formato: string, loginAluno: string): Promise<AxiosPromise> {
    const payload = { imagemBase64, formato, loginAluno };
    const headers = { 'Content-Type': 'application/json; charset=utf-8', 'data-type': 'json' };
    const { data } = await axiosInstance.post(`Alunos/importarFotoAluno/publico`, payload, { headers });

    return data;
  },
  // TODO: validate
  async verificarDuplicidade(registros: string[][], escola: EscolaDb): Promise<string | false> {
    const payload = { registros, escola };
    const headers = { 'Content-Type': 'application/json; charset=utf-8', 'data-type': 'json' };
    const { data } = await axiosInstance.post(`Alunos/verificarDuplicidade`, payload, { headers });

    return data;
  },
  // TODO: validate
  async emailAvisoImportacao(escola: string, totalRegistros: string, situacao: string): Promise<string | undefined> {
    const payload = { escola, totalRegistros, situacao };
    const headers = { 'Content-Type': 'application/json; charset=utf-8', 'data-type': 'json' };
    const { data } = await axiosInstance.post(`Alunos/emailAvisoImportacao`, payload, { headers });

    return data;
  },
  async findFotosAlunosPresenca(presencas: Array<PresencaDb> | undefined): Promise<Array<FindFotoPresencaReturnType>> {
    if (presencas) {
      const payload = { presencas };
      const { data }: AxiosResponse<Array<FindFotoPresencaReturnType>> = await axiosInstance.post(
        `Alunos/findFotosAlunosPresenca`,
        payload
      );

      return data;
    }

    return [];
  },
  async getArrayAlunos(logins: string[]): Promise<Array<StudentBasicData>> {
    const { data } = await axiosInstance.get(`Alunos/getArrayAlunos`, {
      params: {
        logins: encodeURIComponent(logins.join(',')),
      },
    });

    return data;
  },
  async listStudentsEnrollmentData(logins: string[]): Promise<Array<AlunosAtivosType>> {
    const { data }: AxiosResponse<Array<AlunosAtivosType>> = await axiosInstance.get(
      `Alunos/dadosMatriculaTurmaAlunos?logins=${encodeURIComponent(logins.join(','))}`
    );

    return data;
  },
  // TODO: validate
  async arrayAlunosValidarStatus(alunos: string[]): Promise<AlunoValidarStatusType | []> {
    const payload = { alunos };
    const headers = { 'Content-Type': 'application/json; charset=utf-8', 'data-type': 'json' };
    const { data } = await axiosInstance.post(`Alunos/arrayAlunosValidarStatus`, { payload }, { headers });

    return data;
  },
  async recuperarAulaCorrente(turmaId: string): Promise<AulaCorrenteDb> {
    const { data }: AxiosResponse<AulaCorrenteDb> = await axiosInstance.get(`AulasCorrentes/${turmaId}`);

    return data;
  },
  // TODO: validate
  async findPixel(login: string, nome: string, nivel: string): Promise<Array<CodigoDb>> {
    const { data }: AxiosResponse<Array<CodigoDb>> = await axiosInstance.get(
      `Codigos/findPixel?login=${login}&nome=${nome}&nivel=${nivel}`
    );

    return data;
  },
  async findCodigoByAluno(login: string): Promise<Array<CodigoDb>> {
    const { data }: AxiosResponse<Array<CodigoDb>> = await axiosInstance.get(`Codigos/findByLogin?login=${login}`);

    return data;
  },
  async findCodigosAlunoTurma(
    login: string,
    turmaId: string
  ): Promise<{
    ambientesCodigos: string[];
    desafiosUsuarioAmbientes: Array<DesafioAmbienteAluno>;
  }> {
    const {
      data,
    }: AxiosResponse<{
      ambientesCodigos: string[];
      desafiosUsuarioAmbientes: Array<DesafioAmbienteAluno>;
    }> = await axiosInstance.get(`Codigos/recuperarDesafiosAlunoTurma`, {
      params: {
        login,
        turmaId,
      },
    });

    return data;
  },
  async getFotosEStatusLiderAlunos(logins: string[]): Promise<Record<string, DadosFotoEStatusLiderAlunoType>> {
    const { data }: AxiosResponse<Record<string, DadosFotoEStatusLiderAlunoType>> = await axiosInstance.get(
      `Alunos/getFotosEStatusLiderAlunos?logins=${encodeURIComponent(logins.join(','))}`
    );

    return data;
  },
  async recuperarFotosNomesAlunos(logins: string[]): Promise<Array<{ login: string; name: string; urlFoto?: string }>> {
    const { data } = await axiosInstance.get(
      `Alunos/recuperarFotosNomesAlunos?logins=${encodeURIComponent(logins.join(','))}`
    );

    return data;
  },
  async getInfo(accessToken: string): Promise<AlunoInfo> {
    const { data } = await axiosInstance.get(`Alunos/getInfo/publico?accessToken=${accessToken}`);

    return data;
  },
};

export type { AlunoAulasType, Aulasobj };

export default Alunoservices;

import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { QueryClient, UseMutateFunction } from '@tanstack/react-query';
import { useHistory } from 'react-router';

import { useChatbotContext, useUpdateChatbotContext } from 'contexts/ChatbotContextProvider';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { toast } from 'react-hot-toast';

import { Button, CloseButton, Form, Modal, Offcanvas, OverlayTrigger, Tooltip, Row } from 'react-bootstrap';

import { PulseLoader } from 'react-spinners';

import { Mic, ThumbsUp, ThumbsDown, Camera, Paperclip } from 'lucide-react';

import chatIcon from 'assets/img/mmgpt.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'pages/MMCode/mmCode.css';
import { BeatLoader } from 'react-spinners';
import { ChatbotAPI } from 'services/external/ChatbotAPI';
import type {
  ConversationIdentifier,
  NewMessageData,
  Message,
  MessageFeedback,
  ImageData,
} from 'services/external/ChatbotAPI';
import type { AxiosResponse } from 'axios';
import { conversationKeys, courseKeys, knowledgebasesKey } from 'constants/queryKeys';
import BasesConhecimentoIAService from 'services/api/BasesConhecimentoIAService';
import { CursoService } from 'services/api/CursoService';
import { formatCourseModuleToDisplay } from 'util/formatCourseModuleToDisplay';
import getBaseConhecimentoInventoteca from 'util/getBaseConhecimentoInventoteca';

import {
  AMBIENTES_EXTERNOS,
  KITS_TECNOLOGICOS,
  KNOWLEDGE_BASE_CURSO_KIDS,
  KNOWLEDGE_BASE_CURSO_MINI,
  KNOWLEDGE_BASE_CURSO_TEENS,
  KNOWLEDGE_BASE_CURSO_YOUNG,
  PLATAFORMA_MM_CORE,
  REFERENCIAL_PEDAGOGICO,
} from 'constants/knowledgeBaseConstants';
import useIsMobile from 'hooks/useIsMobileDevice';
import { useAuth } from 'contexts/AuthProvider';

const pulseLoaderCss: React.CSSProperties = {
  position: 'absolute',
  bottom: '10px',
  left: '50%',
  transform: 'translateX(-50%)',
};

type ConfirmClearChatModalProps = ConversationIdentifier & {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  clearChatMessagesFromUserSubject: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    ConversationIdentifier,
    {
      previousMessagesFromConversation: Array<Message> | undefined;
    }
  >;
};

const ConfirmClearChatModal = ({
  user,
  subject,
  specificDivisionFromSubject,
  show,
  setShow,
  clearChatMessagesFromUserSubject,
}: ConfirmClearChatModalProps) => {
  const closeModal = () => setShow(false);

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>Confirme</Modal.Title>
        <CloseButton onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>Tem certeza de que deseja limpar essa conversa?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            closeModal();
            clearChatMessagesFromUserSubject(
              { user, subject, specificDivisionFromSubject },
              {
                onError: (err) => {
                  console.error(
                    `Erro ao limpar mensagens do assunto ${subject}${
                      specificDivisionFromSubject ? '/' + specificDivisionFromSubject : ''
                    }`,
                    err
                  );
                },
              }
            );
          }}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

type ChatbotMessageActionsProps = ConversationIdentifier & {
  message: Message;
  queryClient: QueryClient;
};

const ChatbotMessageActions = ({
  user,
  subject,
  specificDivisionFromSubject,
  message,
  queryClient,
}: ChatbotMessageActionsProps) => {
  let feedback: MessageFeedback | undefined;

  const { docId } = message;

  if (message.role === 'assistant') {
    feedback = message.feedback;
  }

  const [openFeedbackObservationSection, setOpenFeedbackObservationSection] = useState(false);

  // manter status de like e dislike enquanto vejo como fazer onMutate melhor
  const [status, setStatus] = useState(feedback?.status);
  const [observation, setObservation] = useState(feedback?.observation);

  const { mutate: updateAssistantMessageFeedback } = useMutation({
    mutationFn: (
      statusOrObservationWithDatetime: (Pick<MessageFeedback, 'status'> | Pick<MessageFeedback, 'observation'>) & {
        datahora: string;
      }
    ) =>
      ChatbotAPI.updateMessageFeedback({
        user,
        subject,
        specificDivisionFromSubject,
        docId,
        feedback: { ...(feedback || {}), ...statusOrObservationWithDatetime },
      }),
    onMutate: (newFeedback) => {
      const previousConversationData = queryClient.getQueryData<Array<Message>>([
        'conversations',
        'subject',
        { user, subject, specificDivisionFromSubject },
      ]);

      const newConversationData = [...(previousConversationData || [])];

      for (const message of newConversationData) {
        if (message.role === 'assistant' && message.docId === docId) {
          message.feedback = {
            ...(message.feedback || {}),
            ...newFeedback,
          };
        }
      }

      queryClient.setQueryData(
        ['conversations', 'subject', { user, subject, specificDivisionFromSubject }],
        newConversationData
      );

      return { previousConversationData };
    },
    onError: (
      err,
      _,
      context:
        | {
            previousConversationData: Array<Message> | undefined;
          }
        | undefined
    ) => {
      console.error('Erro ao atualizar like/feedback', err);

      toast.error('Erro ao atualizar like/feedback! Espere um pouco e tente novamente.');

      if (context?.previousConversationData) {
        queryClient.setQueryData(
          ['conversations', 'subject', { user, subject, specificDivisionFromSubject }],
          context.previousConversationData
        );
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['conversations', 'subject', { user, subject, specificDivisionFromSubject }],
      }),
  });

  return (
    <React.Fragment>
      <div className="message-actions">
        <a
          className="feedback-answer"
          onClick={(evt) => {
            evt.preventDefault();

            setOpenFeedbackObservationSection((prevOpenStatus) => !prevOpenStatus);
          }}
        >
          escrever feedback sobre essa resposta
        </a>
        <ThumbsDown
          className={`bt-thumbs-down ${status === 'disliked' ? 'active' : ''}`}
          onClick={() => {
            setStatus((prevStatus) => (prevStatus === 'disliked' ? undefined : 'disliked'));

            updateAssistantMessageFeedback(
              {
                status: feedback?.status === 'disliked' ? undefined : 'disliked',
                datahora: new Date().toISOString(),
              },
              {
                onError: () => {
                  toast.error('Erro ao atualizar dislike dessa mensagem');
                },
              }
            );
          }}
        />
        <ThumbsUp
          className={`bt-thumbs-up ${status === 'liked' ? 'active' : ''}`}
          onClick={() => {
            setStatus((prevStatus) => (prevStatus === 'liked' ? undefined : 'liked'));

            updateAssistantMessageFeedback(
              {
                status: feedback?.status === 'liked' ? undefined : 'liked',
                datahora: new Date().toISOString(),
              },
              {
                onError: () => {
                  toast.error('Erro ao atualizar like dessa mensagem');
                },
              }
            );
          }}
        />
      </div>
      {openFeedbackObservationSection && (
        <Modal
          key={`feedback_section_${docId}`}
          show={openFeedbackObservationSection}
          onClose={() => {
            setOpenFeedbackObservationSection(false);
          }}
        >
          <Modal.Header>
            <Modal.Title>Feedback</Modal.Title>
            <CloseButton
              onClick={() => {
                setOpenFeedbackObservationSection(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div key={`dados_mensagem_para_feedback_${message.docId}`} className={`mb-3 chatbot-message`}>
              <div className="message-content">
                <div className="message-content-header">
                  <span className="message-user-name">Assistente IA</span>
                  <div className="message-date">{new Date(message.datahora).toLocaleString()}</div>
                </div>
                {message.content}
              </div>
            </div>
            <Form.Control
              className="mb-2 mt-2"
              required
              as="textarea"
              placeholder="Digite aqui seu feedback para esta resposta"
              value={observation || undefined}
              onChange={(evt) => {
                setObservation(evt.target.value || null);
              }}
              name={`feedback_mensagem`}
            />
            <div className="d-flex justify-content-end gap-2">
              <button
                className="bt bt-orange"
                onClick={() => {
                  setOpenFeedbackObservationSection(false);
                }}
              >
                Cancelar
              </button>
              <button
                className="bt bt-green"
                onClick={() => {
                  updateAssistantMessageFeedback(
                    {
                      observation,
                      datahora: new Date().toISOString(),
                    },
                    {
                      onSuccess: () => {
                        setOpenFeedbackObservationSection(false);
                        toast.success(
                          `Feedback ${!!observation ? 'inserido' : 'removido'} com sucesso para esta mensagem.`
                        );
                      },
                    }
                  );
                }}
              >
                Enviar feedback
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

const LoadingMessage = () => (
  <div key={'loading_message_chatbot'} className="chatbot-message">
    <div className="message-content">
      <BeatLoader size="10px" color="#171212" />
    </div>
  </div>
);

export default function Chatbot() {
  const history = useHistory();

  const isMobile = useIsMobile();

  const { escolaId, nome, username, tipo } = useAuth();

  const queryClient = useQueryClient();

  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  useEffect(() => {
    setFullscreen((prev) => {
      if (isMobile) {
        return true;
      }

      return prev;
    });
  }, [isMobile, setFullscreen]);

  const updateChatbotContext = useUpdateChatbotContext();

  const {
    currentLesson,
    currentModule,
    currentCourse,
    currentKnowledgeBase,
    selectedSubject,
    specificDivisionFromSubject,
  } = useChatbotContext();

  const { isLoading: isLoadingModulesFromCourse, data: modulesFromCourse } = useQuery({
    queryKey: courseKeys.modulosCurso(currentCourse?.codigoCurso || '', escolaId || '').queryKey,
    queryFn: () => CursoService.recuperarModulosCurso(currentCourse?.codigoCurso || '', escolaId || ''),
    enabled: !!currentCourse?.codigoCurso && !!escolaId,
  });

  const { isLoading: isLoadingLessonsFromModule, data: lessonsFromModule } = useQuery({
    queryKey: courseKeys.divisaoAulasModulo(currentModule?.codigoCurso || '', currentModule?.codigoModulo || '')
      .queryKey,
    queryFn: () => CursoService.divisaoAulasModulo(currentModule?.codigoCurso || '', currentModule?.codigoModulo || ''),
    enabled: !!currentModule?.codigoCurso && !!currentModule?.codigoModulo,
  });

  const { isPending: isLoadingKnowledgeBasesAndTopics, data: knowledgeBasesAndTopics } = useQuery({
    queryKey: knowledgebasesKey.topicosBases.queryKey,
    queryFn: BasesConhecimentoIAService.recuperarTopicosBases,
  });

  useEffect(() => {
    if (!selectedSubject) {
      let newSelectedSubject = '';

      if (currentKnowledgeBase?.id) newSelectedSubject = currentKnowledgeBase.id;
      else if (currentLesson?.codigoAula) newSelectedSubject = `licao_${currentLesson.codigoAula}`;
      else if (currentModule?.codigoModulo) newSelectedSubject = `modulo_${currentModule.codigoModulo}`;
      else if (currentCourse?.codigoCurso) newSelectedSubject = `curso_${currentCourse.codigoCurso}`;
      else newSelectedSubject = knowledgeBasesAndTopics?.[0]?.id || '';

      updateChatbotContext({ selectedSubject: newSelectedSubject });
    }
  }, [
    selectedSubject,
    currentLesson,
    currentModule,
    currentCourse,
    currentKnowledgeBase,
    knowledgeBasesAndTopics,
    updateChatbotContext,
  ]);

  const specificDivisionOptions = useMemo(() => {
    if (selectedSubject) {
      if (selectedSubject.startsWith('curso_')) {
        const modulesFromCourseOpts =
          modulesFromCourse?.map(({ codigo, nome }) => (
            <option key={codigo} value={codigo}>
              {nome}
            </option>
          )) ?? [];

        return [
          <option key={'Visão Geral'} value={'Visão Geral'}>
            Visão Geral [Selecione para Especializar]
          </option>,
          ...modulesFromCourseOpts,
        ];
      } else if (selectedSubject.startsWith('modulo_')) {
        const lessonsFromModuleOpts =
          lessonsFromModule?.map(({ aulanome, aulacodigo }) => (
            <option key={aulacodigo} value={aulacodigo}>
              {aulanome || ''}
            </option>
          )) ?? [];

        return [
          <option key={'Visão Geral'} value={'Visão Geral'}>
            Visão Geral [Selecione para Especializar]
          </option>,
          ...lessonsFromModuleOpts,
        ];
      }
    }

    return (
      knowledgeBasesAndTopics
        ?.find(({ id }) => id === selectedSubject)
        ?.topicos?.sort((topicA, topicB) => {
          if (topicA.topico === 'Visão Geral') return -1;
          else if (topicB.topico === 'Visão Geral') return 1;

          return topicA.topico.localeCompare(topicB.topico, 'pt-BR', { numeric: true });
        })
        ?.map(({ topico }) => (
          <option key={topico} value={topico}>
            {topico === 'Visão Geral' ? `${topico} [Selecione para Especializar]` : topico}
          </option>
        )) ?? []
    );
  }, [knowledgeBasesAndTopics, selectedSubject, modulesFromCourse, lessonsFromModule]);

  const hasToSelectSpecificDivisionFromSubject = specificDivisionOptions.length > 0;

  useEffect(() => {
    const newSpecificDivisionFromSubject = specificDivisionOptions?.[0]?.props?.value;

    if (
      (!specificDivisionFromSubject || specificDivisionFromSubject === 'Visão Geral') &&
      newSpecificDivisionFromSubject
    ) {
      updateChatbotContext({
        specificDivisionFromSubject: newSpecificDivisionFromSubject,
      });
    }

    const idInventotecaBaseConhecimento = getBaseConhecimentoInventoteca(
      selectedSubject || '',
      specificDivisionFromSubject || newSpecificDivisionFromSubject
    );

    if (idInventotecaBaseConhecimento && !isMobile) {
      history.push(`/knowledgebase/#/info/${idInventotecaBaseConhecimento}`);
    }
  }, [updateChatbotContext, specificDivisionOptions, specificDivisionFromSubject, selectedSubject, history, isMobile]);

  useEffect(() => {
    setImageUrl(undefined);
  }, [selectedSubject, specificDivisionFromSubject]);

  const { mutateAsync: uploadImage, isPending: isUploadingImage } = useMutation({
    mutationFn: (imageData: ImageData) => ChatbotAPI.uploadImage(imageData),
    onSuccess: (newImageUrl) => {
      setImageUrl(newImageUrl);
    },
  });

  if (isUploadingImage) {
    toast.loading('Subindo imagem...', {
      id: 'uploading-image-chatbot',
    });
  } else {
    toast.remove('uploading-image-chatbot');
  }

  const { mutateAsync: deleteImage, isPending: isDeletingImage } = useMutation({
    mutationFn: (imageUrlToDelete: string) => ChatbotAPI.deleteImage(imageUrlToDelete),
    onMutate: (previousImageUrl) => {
      setImageUrl(undefined);

      return { previousImageUrl };
    },
    onError: (
      err,
      _,
      context:
        | {
            previousImageUrl: string;
          }
        | undefined
    ) => {
      console.error(`Erro ao deletar imagem ${context?.previousImageUrl}`, err);

      if (context?.previousImageUrl) {
        setImageUrl(context.previousImageUrl);
      }
    },
  });

  const { data: conversationHistoryOnSubject } = useQuery({
    queryKey: [
      'conversations',
      'subject',
      { user: username || '', subject: selectedSubject, specificDivisionFromSubject },
    ],
    queryFn: () =>
      ChatbotAPI.getConversation({ user: username || '', subject: selectedSubject, specificDivisionFromSubject }),
    enabled: !!username && !!selectedSubject,
  });

  const {
    isFetching: isFetchingChatbotRespone,
    isLoading: isLoadingChatbotResponse,
    refetch: getChatbotResponse,
  } = useQuery({
    queryKey: conversationKeys.chatbotResponse.queryKey,
    queryFn: () =>
      ChatbotAPI.getChatbotResponse({
        currentLesson,
        currentModule,
        currentCourse,
        currentKnowledgeBase,
        conversationHistoryOnSubject,
        selectedSubject,
        specificDivisionFromSubject,
        currentUserType: tipo || '',
      }),
    enabled: false,
  });

  const messagesThreadEndRef = useRef<HTMLDivElement>(null);

  const { mutateAsync: addMessageToConversation } = useMutation({
    mutationFn: ({
      user,
      subject,
      specificDivisionFromSubject,
      message,
    }: ConversationIdentifier & {
      message: NewMessageData;
    }) => ChatbotAPI.addMessageToConversation({ user, subject, specificDivisionFromSubject, message }),
    onMutate: (params) => {
      const previousMessagesFromConversation = queryClient.getQueryData<Array<Message>>([
        'conversations',
        'subject',
        {
          user: username || '',
          subject: selectedSubject,
          specificDivisionFromSubject,
        },
      ]);

      queryClient.setQueryData(
        [
          'conversations',
          'subject',
          {
            user: username || '',
            subject: selectedSubject,
            specificDivisionFromSubject,
          },
        ],
        [...(previousMessagesFromConversation ?? []), params.message]
      );

      return { previousMessagesFromConversation };
    },
    onError: (
      err,
      params,
      context:
        | {
            previousMessagesFromConversation: Array<Message> | undefined;
          }
        | undefined
    ) => {
      console.error(`Erro ao inserir mensagem no chat ${params.subject} de ${params.user}`, err);

      if (context?.previousMessagesFromConversation) {
        queryClient.setQueryData(
          [
            'conversations',
            'subject',
            {
              user: username || '',
              subject: selectedSubject,
              specificDivisionFromSubject,
            },
          ],
          context.previousMessagesFromConversation
        );
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          'conversation',
          'subject',
          {
            user: username || '',
            subject: selectedSubject,
            specificDivisionFromSubject,
          },
        ],
      }),
  });

  const { mutate: clearChatMessagesFromUserSubject } = useMutation({
    mutationFn: ({ user, subject, specificDivisionFromSubject }: ConversationIdentifier) =>
      ChatbotAPI.clearConversation({ user, subject, specificDivisionFromSubject }),
    onMutate: () => {
      const previousMessagesFromConversation = queryClient.getQueryData<Array<Message>>([
        'conversations',
        'subject',
        {
          user: username || '',
          subject: selectedSubject,
          specificDivisionFromSubject,
        },
      ]);

      queryClient.setQueryData(
        [
          'conversations',
          'subject',
          {
            user: username || '',
            subject: selectedSubject,
            specificDivisionFromSubject,
          },
        ],
        [] as Array<Message>
      );

      return { previousMessagesFromConversation };
    },
    onError: (
      err,
      params,
      context:
        | {
            previousMessagesFromConversation: Array<Message> | undefined;
          }
        | undefined
    ) => {
      console.error(
        `Erro ao limpar mensagem do chat ${params.subject}${
          params.specificDivisionFromSubject ? ' (' + params.specificDivisionFromSubject + ')' : ''
        } de ${params.user}`,
        err
      );

      if (context?.previousMessagesFromConversation) {
        queryClient.setQueryData(
          [
            'conversations',
            'subject',
            {
              user: username || '',
              subject: selectedSubject,
              specificDivisionFromSubject,
            },
          ],
          context.previousMessagesFromConversation
        );
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          'conversations',
          'subject',
          {
            user: username || '',
            subject: selectedSubject,
            specificDivisionFromSubject,
          },
        ],
      }),
  });

  const [prompt, setPrompt] = useState('');

  const handleShow = () => {
    setShow(true);
    setFullscreen(false);
  };

  const handleClose = () => setShow(false);
  const toggleFullscreen = () => setFullscreen((prevFullscreen) => !prevFullscreen);

  const addUserMessageAndChatbotReply = useCallback(
    async (userMessageContent: string, options?: { readChatbotReplyOutLoud: boolean }) => {
      const userMessage: NewMessageData = {
        content: userMessageContent,
        role: 'user',
        datahora: new Date().toISOString(),
      };

      if (
        selectedSubject === KITS_TECNOLOGICOS &&
        !!specificDivisionFromSubject &&
        specificDivisionFromSubject !== 'Visão Geral' &&
        imageUrl
      ) {
        userMessage.imageUrl = imageUrl;

        setImageUrl(undefined);
      }

      await addMessageToConversation({
        user: username || '',
        subject: selectedSubject,
        specificDivisionFromSubject,
        message: userMessage,
      });

      const { data: chatbotReply } = await getChatbotResponse();

      await addMessageToConversation({
        user: username || '',
        subject: selectedSubject,
        specificDivisionFromSubject,
        message: {
          content: chatbotReply || '',
          role: 'assistant',
          datahora: new Date().toISOString(),
        },
      });

      if (!!options?.readChatbotReplyOutLoud && 'speechSynthesis' in window && !!chatbotReply) {
        const utterance = new SpeechSynthesisUtterance(chatbotReply);
        utterance.rate = 1.3;

        speechSynthesis.speak(utterance);
      }
    },
    [addMessageToConversation, username, selectedSubject, specificDivisionFromSubject, getChatbotResponse, imageUrl]
  );

  // Se está carregando mensagem do Chatbot ou mensagens foram atualizadas - faz scroll para o fim do chat
  useEffect(() => {
    if (!!conversationHistoryOnSubject) messagesThreadEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [conversationHistoryOnSubject]);

  const {
    finalTranscript,
    listening: isListening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    if (!isListening && finalTranscript) {
      addUserMessageAndChatbotReply(finalTranscript, { readChatbotReplyOutLoud: true });

      resetTranscript();
    }
  }, [isListening, finalTranscript, addUserMessageAndChatbotReply, resetTranscript]);

  const [showConfirm, setShowConfirm] = useState(false);

  const isLoadingSubtopicsForCourseOrModules =
    !!selectedSubject &&
    ((selectedSubject.startsWith('curso_') && isLoadingModulesFromCourse) ||
      (selectedSubject.startsWith('modulo_') && isLoadingLessonsFromModule));

  const isUserInputDisabled =
    isListening ||
    isLoadingChatbotResponse ||
    isFetchingChatbotRespone ||
    isLoadingKnowledgeBasesAndTopics ||
    isLoadingSubtopicsForCourseOrModules ||
    isUploadingImage ||
    isDeletingImage ||
    (hasToSelectSpecificDivisionFromSubject && !specificDivisionFromSubject);

  knowledgeBasesAndTopics?.sort((baseA, baseB) => {
    if (!baseA.baseConhecimento && !baseB.baseConhecimento) return 0;
    else if (!baseA.baseConhecimento) return -1;
    else if (!baseB.baseConhecimento) return 1;

    return baseA.baseConhecimento.localeCompare(baseB.baseConhecimento, 'pt-BR', { numeric: true });
  });

  const suggestions = useMemo(
    () =>
      knowledgeBasesAndTopics
        ?.find(({ id }) => {
          if (
            currentModule?.codigoModulo &&
            currentModule?.codigoCurso &&
            selectedSubject === `modulo_${currentModule.codigoModulo}`
          ) {
            if (currentModule.codigoCurso === 'MYe' || currentModule.codigoCurso === 'MY')
              return id === KNOWLEDGE_BASE_CURSO_YOUNG;
            else if (currentModule.codigoCurso === 'MT' || currentModule.codigoCurso === 'MTe')
              return id === KNOWLEDGE_BASE_CURSO_TEENS;
            else if (currentModule.codigoCurso === 'MP' || currentModule.codigoCurso === 'MPe')
              return id === KNOWLEDGE_BASE_CURSO_MINI;
            else if (currentModule.codigoCurso === 'F1' || currentModule.codigoCurso === 'MKe')
              return id === KNOWLEDGE_BASE_CURSO_KIDS;
          } else if (currentCourse?.codigoCurso && selectedSubject === `curso_${currentCourse.codigoCurso}`) {
            if (currentCourse.codigoCurso === 'MYe' || currentCourse.codigoCurso === 'MY')
              return id === KNOWLEDGE_BASE_CURSO_YOUNG;
            else if (currentCourse.codigoCurso === 'MT' || currentCourse.codigoCurso === 'MTe')
              return id === KNOWLEDGE_BASE_CURSO_TEENS;
            else if (currentCourse.codigoCurso === 'MP' || currentCourse.codigoCurso === 'MPe')
              return id === KNOWLEDGE_BASE_CURSO_MINI;
            else if (currentCourse.codigoCurso === 'F1' || currentCourse.codigoCurso === 'MKe')
              return id === KNOWLEDGE_BASE_CURSO_KIDS;
          }

          return id === selectedSubject;
        })
        ?.topicos?.find(({ topico }) => {
          if (selectedSubject.startsWith('licao_')) return false;

          if (
            currentModule?.codigoCurso &&
            currentModule?.codigoModulo &&
            selectedSubject === `modulo_${currentModule.codigoModulo}` &&
            specificDivisionFromSubject === 'Visão Geral'
          ) {
            return (
              topico === `Módulo ${formatCourseModuleToDisplay(currentModule.codigoCurso, currentModule.codigoModulo)}`
            );
          } else if (
            currentCourse?.codigoCurso &&
            selectedSubject === `codigo_${currentCourse.codigoCurso}` &&
            specificDivisionFromSubject === 'Visão Geral'
          ) {
            return topico === 'Visão Geral';
          }

          return topico === specificDivisionFromSubject;
        })?.sugestoes ?? [],
    [selectedSubject, specificDivisionFromSubject, currentModule, currentCourse, knowledgeBasesAndTopics]
  );

  const hasSelectSomeSpecificComputationalDevice =
    selectedSubject === KITS_TECNOLOGICOS &&
    !!specificDivisionFromSubject &&
    specificDivisionFromSubject !== 'Visão Geral';

  const uploadPhotoRef = useRef<HTMLInputElement>(null);

  const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files === null) {
      return;
    }

    const file = evt.target.files[0];

    if (file) {
      const fileReader = new FileReader();

      const extension = file.name.split('.')?.[1] ?? '';

      fileReader.onload = async () => {
        await uploadImage({
          base64: fileReader.result,
          extension,
        });
      };

      fileReader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment>
      {!show && (
        <button id="open-chat-btn" className="text-end" onClick={handleShow}>
          <img src={chatIcon} />
        </button>
      )}

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className={`mindmakers-chat-gpt ${fullscreen ? 'full-screen-chat-gpt' : ''}`}
      >
        <Offcanvas.Header>
          <div>
            <Offcanvas.Title>Assistente IA. Falar sobre: </Offcanvas.Title>
            <Form.Group id="assuntos" className="mt-3">
              <Form.Select
                onChange={(evt) => {
                  const { value: newSelectedSubject } = evt.target as HTMLSelectElement;

                  if (
                    currentLesson?.codigoCurso &&
                    currentLesson?.codigoModulo &&
                    currentLesson?.codigoAula &&
                    newSelectedSubject === `licao_${currentLesson.codigoAula}`
                  ) {
                    updateChatbotContext({
                      currentModule: {
                        codigoCurso: currentLesson.codigoCurso,
                        codigoModulo: currentLesson.codigoModulo,
                        nomeModulo: `Módulo ${formatCourseModuleToDisplay(
                          currentLesson.codigoCurso,
                          currentLesson.codigoModulo
                        )}`,
                      },
                      selectedSubject: `modulo_${currentLesson.codigoModulo}`,
                      specificDivisionFromSubject: currentLesson.codigoAula,
                    });
                  } else {
                    updateChatbotContext({
                      selectedSubject: newSelectedSubject,
                    });
                  }

                  if (!isMobile) {
                    if (currentLesson && newSelectedSubject === `licao_${currentLesson.codigoAula}`) {
                      history.push(
                        `/lesson/#/?curso=${currentLesson.codigoCurso}&modulo=${currentLesson.codigoModulo}&unidade=${
                          currentLesson.unidade
                        }&aula=${currentLesson.codigoAula}&nome=${encodeURIComponent(
                          currentLesson.nomeAula
                        )}&planoSelecionado=${currentLesson?.planoSelecionado || ''}`
                      );
                    } else if (currentModule && newSelectedSubject === `modulo_${currentModule.codigoModulo}`) {
                      history.push(
                        `/course/#/module?curso=${currentModule.codigoCurso}&modulo=${currentModule.codigoModulo}`
                      );
                    } else if (currentCourse && newSelectedSubject === `curso_${currentCourse.codigoCurso}`) {
                      history.push(`/course/#/list?codigo=${currentCourse.codigoCurso}`);
                    } else {
                      if (newSelectedSubject === REFERENCIAL_PEDAGOGICO)
                        history.push(`/knowledgebase/#/list/?origin=7`);
                      else if (newSelectedSubject === KITS_TECNOLOGICOS)
                        history.push(`/knowledgebase/#/list/?origin=9`);
                      else if (newSelectedSubject === AMBIENTES_EXTERNOS)
                        history.push(`/knowledgebase/#/list/?origin=11`);
                    }
                  }
                }}
                value={selectedSubject}
                name="selected_chatbot_subject"
              >
                {currentCourse?.codigoCurso && (
                  <option id={`curso_${currentCourse.codigoCurso}`} value={`curso_${currentCourse.codigoCurso}`}>
                    {currentCourse.nomeCurso}
                  </option>
                )}
                {currentModule?.codigoModulo && (
                  <option id={`modulo_${currentModule.codigoModulo}`} value={`modulo_${currentModule.codigoModulo}`}>
                    {currentModule.nomeModulo}
                  </option>
                )}
                {currentLesson?.codigoAula && (
                  <option id={`licao_${currentLesson.codigoAula}`} value={`licao_${currentLesson.codigoAula}`}>
                    {currentLesson.nomeAula}
                  </option>
                )}
                {knowledgeBasesAndTopics
                  ?.filter(
                    ({ id }) =>
                      id !== KNOWLEDGE_BASE_CURSO_KIDS &&
                      id !== KNOWLEDGE_BASE_CURSO_MINI &&
                      id !== KNOWLEDGE_BASE_CURSO_TEENS &&
                      id !== KNOWLEDGE_BASE_CURSO_YOUNG
                  )
                  ?.map((knowledgeBase) => (
                    <option key={`knowledge_base_${knowledgeBase.id}`} value={knowledgeBase.id}>
                      {knowledgeBase.baseConhecimento}
                    </option>
                  ))}
              </Form.Select>
              <PulseLoader
                loading={isLoadingKnowledgeBasesAndTopics}
                color="#FF6D00"
                size={8}
                cssOverride={pulseLoaderCss}
              />
            </Form.Group>
            {hasToSelectSpecificDivisionFromSubject && (
              <Row>
                <Form.Group id="sub-assuntos" className="mt-3">
                  <Form.Select
                    disabled={isLoadingSubtopicsForCourseOrModules}
                    onChange={(evt) => {
                      const { value: newSpecificDivisionFromSubject } = evt.target as HTMLSelectElement;

                      if (currentCourse && selectedSubject === `curso_${currentCourse.codigoCurso}`) {
                        const { codigoCurso } = currentCourse;

                        updateChatbotContext({
                          currentModule: {
                            codigoCurso,
                            codigoModulo: newSpecificDivisionFromSubject,
                            nomeModulo: `Módulo ${formatCourseModuleToDisplay(
                              codigoCurso,
                              newSpecificDivisionFromSubject
                            )}`,
                          },
                          selectedSubject: `modulo_${newSpecificDivisionFromSubject}`,
                          specificDivisionFromSubject: `Visão Geral`,
                        });

                        if (!isMobile) {
                          history.push(
                            `/course/#/module?curso=${codigoCurso}&modulo=${newSpecificDivisionFromSubject}`
                          );
                        }
                      } else {
                        updateChatbotContext({
                          specificDivisionFromSubject: newSpecificDivisionFromSubject,
                        });
                      }

                      if (!isMobile) {
                        if (currentModule?.codigoModulo && selectedSubject === `modulo_${currentModule.codigoModulo}`) {
                          if (newSpecificDivisionFromSubject === 'Visão Geral') {
                            history.push(
                              `/course/#/module?curso=${currentModule.codigoCurso}&modulo=${currentModule.codigoModulo}`
                            );
                          } else {
                            const dadosAulaEspecifica = lessonsFromModule?.find(
                              ({ aulacodigo }) => aulacodigo === newSpecificDivisionFromSubject
                            );

                            if (dadosAulaEspecifica) {
                              history.push(
                                `/lesson/#/?curso=${dadosAulaEspecifica.curso}&modulo=${
                                  dadosAulaEspecifica.modulo
                                }&unidade=${dadosAulaEspecifica.unidade}&aula=${
                                  dadosAulaEspecifica.aulacodigo
                                }&nome=${encodeURIComponent(dadosAulaEspecifica.aulanome || '')}&planoSelecionado=`
                              );
                            }
                          }
                        } else if (
                          !selectedSubject.startsWith('curso_') &&
                          !selectedSubject.startsWith('modulo_') &&
                          !selectedSubject.startsWith('licao_')
                        ) {
                          if (newSpecificDivisionFromSubject === 'Visão Geral') {
                            if (selectedSubject === REFERENCIAL_PEDAGOGICO)
                              history.push(`/knowledgebase/#/list/?origin=7`);
                            else if (selectedSubject === KITS_TECNOLOGICOS)
                              history.push(`/knowledgebase/#/list/?origin=9`);
                            else if (selectedSubject === AMBIENTES_EXTERNOS)
                              history.push(`/knowledgebase/#/list/?origin=11`);
                          } else {
                            if (selectedSubject === PLATAFORMA_MM_CORE) {
                              if (newSpecificDivisionFromSubject === 'Base de Conhecimento')
                                history.push(`/knowledgebase/#/list/`);
                              else if (newSpecificDivisionFromSubject === 'Inventoteca') history.push(`/projects/#/`);
                              else if (tipo === 'instrutor' && newSpecificDivisionFromSubject === 'Mural')
                                history.push(`/facilitator/#/mural/`);
                            } else {
                              const idInventotecaBaseConhecimento = getBaseConhecimentoInventoteca(
                                selectedSubject,
                                newSpecificDivisionFromSubject
                              );

                              if (idInventotecaBaseConhecimento)
                                history.push(`/knowledgebase/#/info/${idInventotecaBaseConhecimento}`);
                            }
                          }
                        }
                      }
                    }}
                    value={specificDivisionFromSubject}
                    name="selected_chatbot_sub_subject"
                  >
                    {specificDivisionOptions}
                  </Form.Select>
                  <PulseLoader
                    loading={isLoadingSubtopicsForCourseOrModules}
                    color="#FF6D00"
                    size={8}
                    cssOverride={pulseLoaderCss}
                  />
                </Form.Group>
              </Row>
            )}
          </div>
          <span className={`bt-fullscreen ${fullscreen ? 'minimizar' : ''}`} onClick={toggleFullscreen} />
        </Offcanvas.Header>
        <Offcanvas.Body style={{ backgroundColor: '#edf0f5' }}>
          <div className="messages-thread">
            {conversationHistoryOnSubject?.map((message) => {
              if (message.content === 'ERROR_FETCHING_RESPONSE_CHATBOT') {
                return (
                  <div key={`error_${message.docId}`} className="block-error">
                    Erro ao recuperar resposta do Chatbot. Tente novamente.
                  </div>
                );
              }

              let messageImage: string | undefined = undefined;

              if (message.role !== 'assistant' && message.imageUrl) {
                messageImage = message.imageUrl;
              }

              return (
                <div key={message.docId} className={message.role === 'user' ? 'my-message' : 'chatbot-message'}>
                  <div className="message-content">
                    <div className="message-content-header">
                      <span className="message-user-name">
                        {message.role === 'user' ? nome?.split(' ')?.[0] || username || 'Usuário(a)' : 'Assistente IA'}
                      </span>
                      <div className="message-date">{new Date(message.datahora).toLocaleString()}</div>
                    </div>
                    {message.content}
                    {messageImage && <img src={messageImage} />}
                  </div>

                  {message.role === 'assistant' && !!username ? (
                    <ChatbotMessageActions
                      user={username}
                      subject={selectedSubject}
                      specificDivisionFromSubject={specificDivisionFromSubject}
                      message={message}
                      queryClient={queryClient}
                    />
                  ) : null}
                </div>
              );
            })}
            {(isLoadingChatbotResponse || isFetchingChatbotRespone) && <LoadingMessage />}
            <div id="end_of_messages" ref={messagesThreadEndRef} />
          </div>
          <div className="mt-auto">
            {suggestions.length > 0 && (
              <div className="suggestions-tags" style={{ padding: '20px 20px 0' }}>
                <div style={{ width: '100%', color: 'rgba(255,109,0,1)' }}>Sugestões</div>
                {suggestions.map((suggestion) => (
                  <span
                    key={`suggestion-${selectedSubject}-${specificDivisionFromSubject}-"${suggestion}"`}
                    className="suggestion-tag"
                    style={{ cursor: 'pointer' }}
                    onClick={async () => {
                      await addUserMessageAndChatbotReply(suggestion);
                    }}
                  >
                    {suggestion}
                  </span>
                ))}
              </div>
            )}
            <Form
              className="message-prompt m-0"
              onSubmit={async (evt) => {
                evt.preventDefault();
                evt.stopPropagation();

                const promptBeforeClearing = prompt;

                setPrompt('');

                await addUserMessageAndChatbotReply(promptBeforeClearing);
              }}
            >
              <Form.Control
                required
                name="chatbot_user_question_area"
                as="textarea"
                value={prompt}
                onChange={(evt) => {
                  setPrompt(evt.target.value);
                }}
                onKeyDown={async (evt) => {
                  if (evt.key === 'Enter' && !evt.shiftKey) {
                    evt.preventDefault();

                    const promptBeforeClearing = prompt;

                    setPrompt('');

                    await addUserMessageAndChatbotReply(promptBeforeClearing);
                  }
                }}
                disabled={isUserInputDisabled}
                placeholder={'Digite sua pergunta aqui...'}
              />
              {imageUrl && (
                <span
                  style={{
                    position: 'absolute',
                    height: 30,
                    fontSize: 12,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    fontWeight: 400,
                    top: -15,
                    left: '1rem',
                  }}
                  className="attached-image"
                >
                  <Paperclip color="#636E89" size={16} />
                  Imagem anexada
                  <a
                    style={{
                      display: 'block',
                      marginLeft: 10,
                    }}
                    href="#"
                    onClick={async (evt) => {
                      evt.preventDefault();

                      await deleteImage(imageUrl);
                    }}
                  >
                    remover
                  </a>
                </span>
              )}
              {hasSelectSomeSpecificComputationalDevice && (
                <>
                  <a
                    href=""
                    type="file"
                    className="bt-camera"
                    onClick={(evt) => {
                      evt.preventDefault();
                      uploadPhotoRef?.current?.click();
                    }}
                  >
                    <Camera color="#636E89" />
                  </a>
                  <input
                    type="file"
                    accept="image/*"
                    capture
                    ref={uploadPhotoRef}
                    onChange={handleOnChange}
                    style={{ display: 'none' }}
                  />
                </>
              )}
              {conversationHistoryOnSubject && conversationHistoryOnSubject.length > 0 && (
                <OverlayTrigger
                  key="top"
                  placement="top"
                  trigger={['hover', 'focus']}
                  overlay={<Tooltip id={`limpar-conversa-chat-gpt`}>Limpar conversa</Tooltip>}
                >
                  <button
                    className="chat-trashcan-icon"
                    onClick={(evt) => {
                      evt.preventDefault();
                      evt.stopPropagation();

                      setShowConfirm(true);
                    }}
                  />
                </OverlayTrigger>
              )}
              <button
                disabled={isUserInputDisabled}
                className={`bt-mic ${isListening ? 'mic-listening' : ''}`}
                onClick={(evt) => {
                  evt.preventDefault();

                  if (browserSupportsSpeechRecognition) {
                    SpeechRecognition.startListening({
                      continuous: false,
                      interimResults: false,
                      language: 'pt-BR',
                    });
                  } else {
                    toast.error('Parece que seu browser não suporta reconhecimento de fala!', {
                      id: 'browser_does_not_support_speech_recognition',
                      duration: 2750,
                    });
                  }
                }}
              >
                <Mic color="#636E89" />
              </button>
              <button
                disabled={isUserInputDisabled || !prompt}
                type="submit"
                className={`bt-send ${isUserInputDisabled || !prompt ? 'bt-send-disable' : ''}`}
              />
            </Form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <ConfirmClearChatModal
        subject={selectedSubject}
        specificDivisionFromSubject={specificDivisionFromSubject}
        user={username || ''}
        show={showConfirm}
        setShow={setShowConfirm}
        clearChatMessagesFromUserSubject={clearChatMessagesFromUserSubject}
      />
    </React.Fragment>
  );
}

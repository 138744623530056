import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import DASHBOARD_EN from 'assets/translations/en/dashboard.json';
import LOGIN_EN from 'assets/translations/en/login.json';
import CLASSCONFIG_EN from 'assets/translations/en/class_config.json';
import STUDENTCONFIG_EN from 'assets/translations/en/student_config.json';
import STUDENTIMPORT_EN from 'assets/translations/en/student_import.json';
import FACILITATORCONFIG_EN from 'assets/translations/en/facilitator_config.json';
import TOAST_EN from 'assets/translations/en/toast.json';
import CLASSREGISTRATION_EN from 'assets/translations/en/class_registration.json';
import TRACKING_EN from 'assets/translations/en/track.json';
import MURAL_EN from 'assets/translations/en/mural.json';
import PROJECTS_EN from 'assets/translations/en/projects.json';
import COURSE_EN from 'assets/translations/en/course.json';
import LEVELLING_EN from 'assets/translations/en/levelling.json';
import LESSONPLAN_EN from 'assets/translations/en/lesson_plan.json';
import BRIEFING_EN from 'assets/translations/en/briefing.json';
import SELFSTUDY_EN from 'assets/translations/en/self_study.json';
import RATINGS_EN from 'assets/translations/en/ratings.json';
import RUN_EN from 'assets/translations/en/run.json';
import MYPROGRESS_EN from 'assets/translations/en/my_progress.json';
import MYWORKS_EN from 'assets/translations/en/my_works.json';
import MYFILES_EN from 'assets/translations/en/my_files.json';
import STUDENTSFILES_EN from 'assets/translations/en/students_files.json';
import MYLESSONS_EN from 'assets/translations/en/my_lessons.json';
import PROJECTEVAL_EN from 'assets/translations/en/projecteval.json';
import ONLINETEST_EN from 'assets/translations/en/onlinetest.json';
import PLURALLSYNC_EN from 'assets/translations/en/plurall_sync.json';
import NOTICEBOARD_EN from 'assets/translations/en/notice_board.json';
import ANNOUNCEMENTBOARD_EN from 'assets/translations/en/announcement_board.json';
import GUARDIANS_EN from 'assets/translations/en/guardian.json';
import PRELIMINARYTRAINING_EN from 'assets/translations/en/preliminary_training.json';

import DASHBOARD_PTBR from 'assets/translations/pt-BR/dashboard.json';
import LOGIN_PTBR from 'assets/translations/pt-BR/login.json';
import CLASSCONFIG_PTBR from 'assets/translations/pt-BR/class_config.json';
import STUDENTCONFIG_PTBR from 'assets/translations/pt-BR/student_config.json';
import STUDENTIMPORT_PTBR from 'assets/translations/pt-BR/student_import.json';
import FACILITATORCONFIG_PTBR from 'assets/translations/pt-BR/facilitator_config.json';
import TOAST_PTBR from 'assets/translations/pt-BR/toast.json';
import CLASSREGISTRATION_PTBR from 'assets/translations/pt-BR/class_registration.json';
import TRACKING_PTBR from 'assets/translations/pt-BR/track.json';
import MURAL_PTBR from 'assets/translations/pt-BR/mural.json';
import PROJECTS_PTBR from 'assets/translations/pt-BR/projects.json';
import COURSE_PTBR from 'assets/translations/pt-BR/course.json';
import LEVELLING_PTBR from 'assets/translations/pt-BR/levelling.json';
import LESSONPLAN_PTBR from 'assets/translations/pt-BR/lesson_plan.json';
import BRIEFING_PTBR from 'assets/translations/pt-BR/briefing.json';
import SELFSTUDY_PTBR from 'assets/translations/pt-BR/self_study.json';
import RATINGS_PTBR from 'assets/translations/pt-BR/ratings.json';
import RUN_PTBR from 'assets/translations/pt-BR/run.json';
import MYPROGRESS_PTBR from 'assets/translations/pt-BR/my_progress.json';
import MYWORKS_PTBR from 'assets/translations/pt-BR/my_works.json';
import MYFILES_PTBR from 'assets/translations/pt-BR/my_files.json';
import STUDENTSFILES_PTBR from 'assets/translations/pt-BR/students_files.json';
import MYLESSONS_PTBR from 'assets/translations/pt-BR/my_lessons.json';
import PROJECTEVAL_PTBR from 'assets/translations/pt-BR/projecteval.json';
import ONLINETEST_PTBR from 'assets/translations/pt-BR/onlinetest.json';
import PLURALLSYNC_PTBR from 'assets/translations/pt-BR/plurall_sync.json';
import NOTICEBOARD_PTBR from 'assets/translations/pt-BR/notice_board.json';
import ANNOUNCEMENTBOARD_PTBR from 'assets/translations/pt-BR/announcement_board.json';
import GUARDIANS_PTBR from 'assets/translations/pt-BR/guardian.json';
import PRELIMINARYTRAINING_PTBR from 'assets/translations/pt-BR/preliminary_training.json';

export const resources = {
  en: {
    dashboard: DASHBOARD_EN,
    login: LOGIN_EN,
    classconfig: CLASSCONFIG_EN,
    studentconfig: STUDENTCONFIG_EN,
    studentimport: STUDENTIMPORT_EN,
    facilitatorconfig: FACILITATORCONFIG_EN,
    toast: TOAST_EN,
    classregistration: CLASSREGISTRATION_EN,
    track: TRACKING_EN,
    mural: MURAL_EN,
    rte: { 'default.placeholder': 'Type something' },
    projects: PROJECTS_EN,
    course: COURSE_EN,
    levelling: LEVELLING_EN,
    lessonplan: LESSONPLAN_EN,
    briefing: BRIEFING_EN,
    selfstudy: SELFSTUDY_EN,
    ratings: RATINGS_EN,
    run: RUN_EN,
    myprogress: MYPROGRESS_EN,
    myworks: MYWORKS_EN,
    myfiles: MYFILES_EN,
    studentsfiles: STUDENTSFILES_EN,
    mylessons: MYLESSONS_EN,
    me: { 'header.page.title': 'My Data', 'my.data.card.header.title': 'My Data' },
    projecteval: PROJECTEVAL_EN,
    onlinetest: ONLINETEST_EN,
    plurallsync: PLURALLSYNC_EN,
    noticeboard: NOTICEBOARD_EN,
    announcementboard: ANNOUNCEMENTBOARD_EN,
    guardians: GUARDIANS_EN,
    preliminarytraining: PRELIMINARYTRAINING_EN,
  },
  'pt-BR': {
    dashboard: DASHBOARD_PTBR,
    login: LOGIN_PTBR,
    classconfig: CLASSCONFIG_PTBR,
    studentconfig: STUDENTCONFIG_PTBR,
    studentimport: STUDENTIMPORT_PTBR,
    facilitatorconfig: FACILITATORCONFIG_PTBR,
    toast: TOAST_PTBR,
    classregistration: CLASSREGISTRATION_PTBR,
    track: TRACKING_PTBR,
    mural: MURAL_PTBR,
    rte: { 'default.placeholder': 'Digite algo' },
    projects: PROJECTS_PTBR,
    course: COURSE_PTBR,
    levelling: LEVELLING_PTBR,
    lessonplan: LESSONPLAN_PTBR,
    briefing: BRIEFING_PTBR,
    selfstudy: SELFSTUDY_PTBR,
    ratings: RATINGS_PTBR,
    run: RUN_PTBR,
    myprogress: MYPROGRESS_PTBR,
    myworks: MYWORKS_PTBR,
    myfiles: MYFILES_PTBR,
    studentsfiles: STUDENTSFILES_PTBR,
    mylessons: MYLESSONS_PTBR,
    me: { 'header.page.title': 'Meus Dados', 'my.data.card.header.title': 'Meus Dados' },
    projecteval: PROJECTEVAL_PTBR,
    onlinetest: ONLINETEST_PTBR,
    plurallsync: PLURALLSYNC_PTBR,
    noticeboard: NOTICEBOARD_PTBR,
    announcementboard: ANNOUNCEMENTBOARD_PTBR,
    guardians: GUARDIANS_PTBR,
    preliminarytraining: PRELIMINARYTRAINING_PTBR,
  },
} as const;

export const namespaces = [
  'dashboard',
  'login',
  'classconfig',
  'studentconfig',
  'studentimport',
  'facilitatorconfig',
  'toast',
  'classregistration',
  'track',
  'mural',
  'rte',
  'projects',
  'course',
  'levelling',
  'lessonplan',
  'briefing',
  'selfstudy',
  'ratings',
  'run',
  'myprogress',
  'myworks',
  'myfiles',
  'studentsfiles',
  'mylessons',
  'me',
  'projecteval',
  'onlinetest',
  'plurallsync',
  'noticeboard',
  'announcementboard',
  'guardians',
  'preliminarytraining',
] as const;

i18n.use(initReactI18next).init({
  resources,
  // lng: i18n.options.lng,
  fallbackLng: 'pt-BR',
  interpolation: {
    escapeValue: false,
  },
  defaultNS: 'dashboard',
  ns: namespaces,
});

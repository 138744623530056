import React, { lazy, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import 'pages/MMCode/mmCode.css';

import { componentLoader } from './componentLoader';

import PrivateRoute from './PrivateRoute';

const HomeApp = lazy(() => componentLoader(() => import('./IndexApp/IndexApp')));

const LoginPage = lazy(() => componentLoader(() => import('pages/Login')));

const SelfieLoginPage = lazy(() => componentLoader(() => import('pages/Selfie/Login')));
const CompsciLoginPage = lazy(() => componentLoader(() => import('pages/Compsci/Login')));

const ForgotPasswordRoutes = lazy(() => import('pages/EsqueciMinhaSenha/forgotPasswordRoute'));

const NotFound = lazy(() => componentLoader(() => import('pages/NotFound/NotFound')));

const InventotecaRoutes = lazy(() => componentLoader(() => import('pages/Projects/inventotecaRoute')));

const KnowledgeBaseRoutes = lazy(() => componentLoader(() => import('pages/KnowledgeBase/knowledgeBaseRoutes')));

const AdminRoutes = lazy(() => componentLoader(() => import('pages/Admin/adminRoute')));

const AssessoriaRoutes = lazy(() => componentLoader(() => import('pages/Assesssoria/assessoriaRoute')));

const SchoolRoutes = lazy(() => componentLoader(() => import('pages/Escola/SchoolRoute')));

const MMCodeRoutes = lazy(() => componentLoader(() => import('pages/MMCode/mmcodeRoute')));

const StudentRoutes = lazy(() => componentLoader(() => import('pages/Student/studentRoute')));
const StudentCWRoutes = lazy(() => componentLoader(() => import('pages/StudentCW/studentCWRoute')));

const FacilitatorRoutes = lazy(() => componentLoader(() => import('pages/Facilitator/facilitatorRoute')));

const SelfieRoutes = lazy(() => componentLoader(() => import('pages/Selfie/SelfieRoute')));

const AnalyticsRoutes = lazy(() => componentLoader(() => import('pages/Analytics/AnalyticsRoute')));

const AITrainingRoutes = lazy(() => componentLoader(() => import('pages/AITraining/AITrainingRoute')));

const RunRoutes = lazy(() => componentLoader(() => import('pages/Run/runRoute')));

const CourseRoutes = lazy(() => componentLoader(() => import('pages/Course/courseRoute')));

const CourseV2Routes = lazy(() => componentLoader(() => import('pages/CourseV2/courseRoute')));

const LessonRoutes = lazy(() => componentLoader(() => import('pages/Lesson/lessonRoute')));

const SelfStudy = lazy(() => componentLoader(() => import('pages/SelfStudy/selfStudyRoute')));

const OnlineTestRoutes = lazy(() => componentLoader(() => import('pages/OnlineTest/onlineTestRoute')));

export const AppRoutes = () => {
  return (
    <Switch>
      {/* Rotas implementadas oficiais */}

      <Route path="/mmcode" component={MMCodeRoutes} />

      {/* Novas Rotas privadas por tipo de User mmCode */}
      <PrivateRoute path="/student" component={StudentRoutes} allowedUserTypes={['aluno']} mmcodelogin />
      <PrivateRoute path="/studentcw" component={StudentCWRoutes} allowedUserTypes={['aluno']} mmcodelogin />
      <PrivateRoute path="/facilitator" component={FacilitatorRoutes} allowedUserTypes={['instrutor']} mmcodelogin />
      <PrivateRoute path="/run" component={RunRoutes} allowedUserTypes={['aluno', 'instrutor']} mmcodelogin />
      <PrivateRoute path="/selfstudy" component={SelfStudy} allowedUserTypes={['instrutor']} mmcodelogin />
      <PrivateRoute
        path="/selfie"
        component={SelfieRoutes}
        allowedUserTypes={['somosselfieadmin', 'somosselfieproduto']}
        selfielogin
      />
      <PrivateRoute
        path="/analytics"
        component={AnalyticsRoutes}
        allowedUserTypes={['admin', 'assessoria', 'escola', 'instrutor']}
        mmcodelogin
      />

      {/* <PrivateRoute
      path="/compsci"
      component={AnalyticsRoutes}
      allowedUserTypes={['admin', 'assessoria', 'escola']}
      compscilogin
    />
    <PrivateRoute
      path="/compsci" component={StudentRoutes} allowedUserTypes={['aluno']}
      compscilogin
    />
    <PrivateRoute
      path="/compsci" component={FacilitatorRoutes} allowedUserTypes={['instrutor']}
      compscilogin
    /> */}

      <PrivateRoute path="/training" component={AITrainingRoutes} allowedUserTypes={['admin', 'assessoria']} />
      <PrivateRoute
        path="/onlinetest"
        component={OnlineTestRoutes}
        allowedUserTypes={['aluno', 'instrutor', 'assessoria']}
        mmcodelogin
      />

      <Route path="/selfielogin" component={SelfieLoginPage} />
      <Route path="/compscilogin" component={CompsciLoginPage} />

      <Route path="/course" component={CourseRoutes} />
      <Route path="/coursev2" component={CourseV2Routes} />
      <Route path="/lesson" component={LessonRoutes} />
      <Route path="/projects" component={InventotecaRoutes} />
      <Route path="/knowledgebase" component={KnowledgeBaseRoutes} />
      <Route path="/forgot-password" component={ForgotPasswordRoutes} />

      <PrivateRoute path="/school" component={SchoolRoutes} allowedUserTypes={['escola']} />

      {/* Rotas a desenvolver */}

      {!import.meta.env.PROD && <Route exact path="/" component={HomeApp} />}

      {!import.meta.env.PROD && <Route path="/login" component={LoginPage} />}

      {/* Rotas privadas por tipo de User */}

      {!import.meta.env.PROD && <PrivateRoute path="/admin" component={AdminRoutes} allowedUserTypes={['admin']} />}

      {!import.meta.env.PROD && (
        <PrivateRoute path="/assessoria" component={AssessoriaRoutes} allowedUserTypes={['assessoria']} />
      )}

      <Route path="*" component={NotFound} />
    </Switch>
  );
};

import { AxiosPromise, AxiosResponse } from 'axios';

import { axiosInstance } from 'lib/axios';

interface InstrutorProjetosOBJ {
  projetos: Array<InstrutorProjetos>;
}

interface InstrutorTodosProjetosOBJ {
  todosProjetos: Array<InstrutorProjetos>;
}

export interface InstrutorProjetos {
  id: string;
  autor: string | null;
  codigoultimaaula: string | null;
  compartilhado: string | null;
  concluiuavaliacao: string | null;
  data: string;
  dataatualizacao: string;
  datacriacao: string;
  equipe: string[];
  equipetextual: string;
  grupoprojetos: string | null;
  idsubmissaoatividade: string;
  imagensanexo: Array<Imagensanexo> | null;
  instrutores: string[];
  inventotecareferencia: string;
  itemavaliacaopratica: string | null;
  liderequipe: string;
  liderequipe2?: string | null | undefined;
  modulonome: string | null;
  nomeproduto: string | null;
  notasindividuais: string | null;
  praticatitulo: string;
  premios: string | null;
  projetoatividades: Array<Projetoatividades> | null;
  relatorio: string | null;
  status: string;
  tecnologiascode: number[] | null;
  tecnologiasmaker: number[] | null;
  titulo: string;
  tituloOriginal: string;
  totallikesmindmaker: string | null;
  totallikesscratch: string | null;
  totallikesyoutube: string | null;
  totalpontos: string | null;
  totalviewsmindmaker: string | null;
  totalviewsscratch: string | null;
  totalviewsyoutube: string | null;
  totalvotos: string | null;
  turma: string;
  uri: string | null;
  uriLogoOrignal: string;
  urilogo: string;
  urlalunoequipe: Urlalunoequipe | null;
  usuarioatualizacao: string | null;
  usuariocriacao: string | null;
  videoyoutube: string | null;
}

interface Projetoatividades {
  descricao: string;
  execucao: number | null;
  feito: boolean;
  ordem: number;
  titulo: string;
}
interface Imagensanexo {
  referencia: string;
  baixaresolucaourl: string;
  altaresolucaourl: string;
}

interface Urlalunoequipe {
  idaluno: string;
  url: string;
}

interface InstrutorAlunos {
  alunos: Record<string, string>;
}

export interface InstrutorTurmas {
  instrutor: InstrutorDb;
}

type InstrutorAutoestudo = NonNullable<InstrutorDb['turmasAutoestudo']>;

type TurmaAutoestudoComDataInicio = {
  idTurma: string;
  nome: string;
  dataInicio: Date;
}

interface MmCodeStatus {
  login: string;
  facilitadormmcode: boolean;
}

interface FacilitadorESSO {
  login: string;
  nome: string;
  pwd: string;
  apelido: string;
  email: string;
  status: string;
  sexo: string;
  telefone: string;
  escola: string;
  turmas: InstrutorTurmas;
  turmasAutoestudo: InstrutorTurmas;
  escolaEAtivo: string;
  usuarioCriacao: string;
  dataCriacao: string;
  usuarioAtualizacao: string;
  dataAtualizacao: string;
  fotoUrl: string;
  fotoOriginalUrl: string;
  grupoEnvioEmail: string;
  facilitadorEc: string;
  dataNascimento: string;
  tamanhoCamiseta: string;
  turmasProva: string;
  ssotype: string;
  ssoidentificador: string;
}

interface ProjetoPorPratica extends ProjetoDb {
  tituloOriginal: PraticaDb['nome'];
  uriLogoOrignal: PraticaDb['imagemUrl']; // nome está desse jeito mesmo
}

interface EmailDemonstracaoEnviarType {
  turmaId: string;
  aulaCodigo: string;
  alunoLogin: string;
  emailInstrutor: string;
}

export type ProjetoTurma = ProjetoDb & { tituloOriginal?: PraticaDb['nome']; uriLogoOriginal?: PraticaDb['imagemUrl'] };

export const InstrutoresServices = {
  async list(): Promise<Array<InstrutorDb>> {
    const { data }: AxiosResponse<Array<InstrutorDb>> = await axiosInstance.get('Instrutores');
    return data;
  },
  async exists(id: string): Promise<boolean> {
    let method = `Instrutores/${id}/exists`;

    if (id.includes('/')) {
      method = `Instrutores/existsByIdWithInvalidCharacters/?id=${encodeURIComponent(id)}`;
    }

    const { data } = await axiosInstance.get(method);

    if (typeof data === 'boolean') {
      return data;
    }

    if (data.hasOwnProperty('exists')) {
      return data.exists;
    }

    return false;
  },
  async findById(id: string): Promise<InstrutorDb> {
    let method = 'Instrutores/' + id;

    if (id.includes('/')) method = 'Instrutores/findByIdWithInvalidCharacters?id=' + id;

    const { data }: AxiosResponse<InstrutorDb> = await axiosInstance.get(method);
    return data;
  },
  async recuperaInstrutorESSO(facilidatorId: string): Promise<FacilitadorESSO> {
    const { data }: AxiosResponse<FacilitadorESSO> = await axiosInstance.get(
      `Instrutores/recuperaInstrutorESSO?id=${encodeURIComponent(facilidatorId)}`
    );
    return data;
  },
  async delete(id: string): Promise<AxiosPromise> {
    const { data } = await axiosInstance.delete(`Instrutores/${id}`);
    return data;
  },
  // TODO: validate
  async insert(payload: InstrutorDb): Promise<AxiosPromise> {
    const { data } = await axiosInstance.put('Instrutores/', payload);
    return data;
  },
  async update(payload: InstrutorDb): Promise<InstrutorDb> {
    const { data } = await axiosInstance.post('Instrutores', payload);
    return data;
  },
  async projetos(): Promise<Array<InstrutorProjetos>> {
    const { data }: AxiosResponse<InstrutorProjetosOBJ> = await axiosInstance.get(`Instrutores/projetos`);
    return data.projetos;
  },
  async todosProjetos(): Promise<Array<InstrutorProjetos>> {
    const { data }: AxiosResponse<InstrutorTodosProjetosOBJ> = await axiosInstance.get(`Instrutores/todosProjetos`);
    return data.todosProjetos;
  },
  async projetosPorTurma(turmaId: string): Promise<Array<ProjetoTurma>> {
    const { data }: AxiosResponse<Array<ProjetoTurma>> = await axiosInstance.get(`Instrutores/projetosPorTurma`, {
      params: {
        turmaId,
      },
    });

    return data;
  },
  async projetosPorPratica(codigoPratica: string): Promise<Array<ProjetoPorPratica>> {
    const { data }: AxiosResponse<Array<ProjetoPorPratica>> = await axiosInstance.get(
      `Instrutores/projetosPorPratica?codigoPratica=${codigoPratica}`
    );
    return data;
  },
  async projetosPorIdSubmissao(idSubmissao: string): Promise<Array<ProjetoDb>> {
    const { data }: AxiosResponse<Array<ProjetoDb>> = await axiosInstance.get(
      `Instrutores/projetosPorIdSubmissao?idSubmissao=${idSubmissao}`
    );
    return data;
  },
  async alunos(): Promise<InstrutorAlunos> {
    const { data }: AxiosResponse<InstrutorAlunos> = await axiosInstance.get(`Instrutores/alunos`);
    return data;
  },
  async turmas(): Promise<InstrutorDb['turmas']> {
    const { data }: AxiosResponse<InstrutorTurmas> = await axiosInstance.get(`Instrutores/turmas`);

    return data.instrutor.turmas;
  },
  async turmasEscola(accessToken: string, escolaId: string): Promise<InstrutorDb['turmas']> {
    const { data } = await axiosInstance.get(
      `Instrutores/turmasEscola?accessToken=${accessToken}&escolaId=${escolaId}`
    );

    return data;
  },
  async instrutorInstance(): Promise<InstrutorTurmas> {
    const { data }: AxiosResponse<InstrutorTurmas> = await axiosInstance.get(`Instrutores/instrutorInstance`);
    return data;
  },
  async listAtivosByEscolaLogada(acessToken: string, idEscolaRecebido: string): Promise<Array<InstrutorDb>> {
    const { data }: AxiosResponse<{ instrutores: Array<InstrutorDb> }> = await axiosInstance.get(
      `Escolas/instrutoresAtivos?accessToken=${acessToken}&idEscolaRecebido=${idEscolaRecebido}`
    );

    return data.instrutores;
  },
  async recuperarTurmasAutoEstudo(): Promise<InstrutorAutoestudo | null> {
    const { data }: AxiosResponse<InstrutorAutoestudo | null> = await axiosInstance.get(
      `Instrutores/recuperarTurmasAutoEstudo`
    );
    return data;
  },
  async recuperarTurmasAutoestudoComDataInicio(): Promise<Array<TurmaAutoestudoComDataInicio> | null> {
    const { data }: AxiosResponse<Array<TurmaAutoestudoComDataInicio> | null> = await axiosInstance.get(
      `Instrutores/recuperarTurmasAutoestudoComDataInicio`
    );
    return data;
  },

  async enviarEmailDemonstracao(payload: EmailDemonstracaoEnviarType): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('Instrutores/enviarEmailDemonstracao', payload);
    return data;
  },
  async getmmcodestatus(id: string): Promise<MmCodeStatus> {
    const { data }: AxiosResponse<MmCodeStatus> = await axiosInstance.get(`Instrutores/getmmcodestatus?id=${id}`);
    return data;
  },
};

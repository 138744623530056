export const formatCourseModuleToDisplay = (cursoCodigo: string, modulo: string) => {
  switch (cursoCodigo) {
    // mmCode Kids
    case 'CK':
      if (modulo === 'CK1') return 'CK1';
      if (modulo === 'CK2') return 'CK2';
      if (modulo === 'CK3') return 'CK3';
      if (modulo === 'CK4') return 'CK4';
      if (modulo === 'CK5') return 'CK5';
      if (modulo === 'CK6') return 'CK6';
      return modulo;

    // mmCode Teens
    case 'MA':
      if (modulo === 'MA1') return 'CT1';
      if (modulo === 'MA2') return 'CT2';
      if (modulo === 'MA3') return 'CT3';
      if (modulo === 'MA4') return 'CT4';
      if (modulo === 'MA5') return 'CT5';
      if (modulo === 'MA6') return 'CT6';
      return modulo;

    // mmCode Teens
    case 'MAe':
      if (modulo === 'MAe1') return 'CT1e';
      if (modulo === 'MAe2') return 'CT2e';
      if (modulo === 'MAe3') return 'CT3e';
      if (modulo === 'MAe4') return 'CT4e';
      if (modulo === 'MAe5') return 'CT5e';
      if (modulo === 'MAe6') return 'CT6e';
      return modulo;

    // Pensamento Computacional

    // Curso Kids
    case 'K':
      if (modulo === 'M0') return 'K0';
      if (modulo === 'M1') return 'K1+';
      if (modulo === 'M1r') return 'K1';
      if (modulo === 'M2') return 'K2';
      if (modulo === 'M3') return 'K3';
      if (modulo === 'M4') return 'K4';
      if (modulo === 'M5') return 'K5';
      if (modulo === 'M6') return 'K6';
      if (modulo === 'M01') return 'K0-1';
      if (modulo === 'M02') return 'K0-2';
      if (modulo === 'M01R') return 'K0+1';
      if (modulo === 'M02R') return 'K0+2';
      return modulo;

    // Curos Kids - English
    case 'Ke':
      if (modulo === 'M0') return 'K0e';
      if (modulo === 'M1') return 'K1e+';
      if (modulo === 'M1r') return 'K1e';
      if (modulo === 'M2') return 'K2e';
      if (modulo === 'M3') return 'K3e';
      if (modulo === 'M4') return 'K4e';
      if (modulo === 'M5') return 'K5e';
      if (modulo === 'M6') return 'K6e';
      if (modulo === 'M01') return 'K0e-1';
      if (modulo === 'M02') return 'K0e-2';
      if (modulo === 'M01R') return 'K0e+1';
      if (modulo === 'M02R') return 'K0e+2';
      return modulo;

    // Curso Teens
    case 'T':
      if (modulo === 'M1') return 'T1';
      if (modulo === 'M2') return 'T2';
      if (modulo === 'M3') return 'T3';
      if (modulo === 'M4') return 'T4';
      if (modulo === 'M5') return 'T5';
      if (modulo === 'M6') return 'T6';
      return modulo;

    // Curso Teens - English
    case 'Te':
      if (modulo === 'M1') return 'T1e';
      if (modulo === 'M2') return 'T2e';
      if (modulo === 'MT3') return 'T3e';
      if (modulo === 'MT4') return 'T4e';
      return modulo;

    // Curso Fundamental 1 - Kids
    case 'F1':
      if (modulo === 'M0') return 'MK0';
      if (modulo === 'M1') return 'MK1+';
      if (modulo === 'M1r') return 'MK1';
      if (modulo === 'M2') return 'MK2';
      if (modulo === 'M3') return 'MK3';
      if (modulo === 'M4') return 'MK4';
      if (modulo === 'M5') return 'MK5';
      if (modulo === 'M6') return 'MK6';
      if (modulo === 'M7') return 'MK7';
      if (modulo === 'M8') return 'MK8';
      if (modulo === 'M01') return 'MK0-1';
      if (modulo === 'M02') return 'MK0-2';
      if (modulo === 'M01R') return 'MK0+1';
      if (modulo === 'M02R') return 'MK0+2';
      return modulo;

    // Curso Fundamental 2 - Teens
    case 'MT':
      if (modulo === 'MT1') return 'MT1';
      if (modulo === 'MT2') return 'MT2';
      if (modulo === 'MT3') return 'MT3';
      if (modulo === 'MT4') return 'MT4';
      if (modulo === 'MT5') return 'MT5';
      if (modulo === 'MT6') return 'MT6';
      if (modulo === 'MT7') return 'MT7';
      if (modulo === 'MT8') return 'MT8';
      if (modulo === 'MT9') return 'MT9';
      if (modulo === 'MT10') return 'MT10';
      return modulo;

    // Curso Fundamental 2 - 9 ano
    case 'MY':
      if (modulo === 'MY01') return 'MY0-1';
      if (modulo === 'MY02') return 'MY0-2';
      if (modulo === 'MY0') return 'MY0-1 v.1';
      if (modulo === 'MY0-2') return 'MY0-2 v.1';
      return modulo;

    // Fundamental 1 - English
    case 'MKe':
      if (modulo === 'M0') return 'MK0e';
      if (modulo === 'M1') return 'MK1e+';
      if (modulo === 'M1r') return 'MK1e';
      if (modulo === 'M2') return 'MK2e';
      if (modulo === 'M3') return 'MK3e';
      if (modulo === 'M4') return 'MK4e';
      if (modulo === 'M5') return 'MK5e';
      if (modulo === 'M6') return 'MK6e';
      if (modulo === 'M7') return 'MK7e';
      if (modulo === 'M8') return 'MK8e';
      if (modulo === 'M9') return 'MK9e';
      if (modulo === 'M01') return 'MK0e-1';
      if (modulo === 'M02') return 'MKe0-2';
      if (modulo === 'M01R') return 'MK0e+1';
      if (modulo === 'M02R') return 'MK0e+2';
      return modulo;

    // Fundamental 2 - English
    case 'MTe':
      if (modulo === 'M1') return 'MT1e';
      if (modulo === 'M2') return 'MT2e';
      if (modulo === 'MT3') return 'MT3e';
      if (modulo === 'MT4') return 'MT4e';
      if (modulo === 'MT5') return 'MT5e';
      if (modulo === 'MT6') return 'MT6e';
      if (modulo === 'MT7') return 'MT7e';
      if (modulo === 'MT8') return 'MT8e';
      if (modulo === 'MT9') return 'MT9e';
      if (modulo === 'MT10') return 'MT10e';
      return modulo;

    // Mind Makers Mini
    case 'MP':
      if (modulo === 'MP1') return 'MM1';
      if (modulo === 'MP2') return 'MM2';
      if (modulo === 'MP3') return 'MM3';
      if (modulo === 'MP4') return 'MM4';
      if (modulo === 'MP4Indi') return 'MM4 Indi';
      if (modulo === 'MP5') return 'MM5';
      if (modulo === 'MP6') return 'MM6';
      return modulo;

    // Mind Makers Mini - English
    case 'MPe':
      if (modulo === 'MP1e') return 'MM1e';
      if (modulo === 'MP2e') return 'MM2e';
      if (modulo === 'MP3e') return 'MM3e';
      if (modulo === 'MP4e') return 'MM4e';
      if (modulo === 'MP4eIndi') return 'MM4e Indi';
      if (modulo === 'MP5e') return 'MM5e';
      if (modulo === 'MP6') return 'MM6e';
      return modulo;

    // Mind Makers Max
    case 'MM':
      if (modulo === 'M3K') return 'XK3';
      if (modulo === 'M3T') return 'XT3';
      return modulo;

    // Curso de Férias
    case 'DR':
      return 'Mini Curso';

    case 'SC':
      return 'Mini Curso';

    // Empreendedorismo Criativo

    case 'EC':
      if (modulo === 'EC1') return 'EC03';
      if (modulo === 'EC2') return 'EC04';
      if (modulo === 'EC01') return 'EC01';
      if (modulo === 'EC02') return 'EC02';
      return modulo;

    case 'DEMOSTRACAO':
      // if (modulo === 'M22') return 'Construcionismo S21';
      return modulo;

    default:
      return modulo;
  }
};

import React, { useEffect } from 'react';
import { Redirect, Route, useLocation, RouteProps } from 'react-router-dom';

import { useAuth } from 'contexts/AuthProvider';
import LocalStorage from 'util/local-storage';

type UserType = 'admin' | 'assessoria' | 'escola' | 'instrutor' | 'aluno' | 'somosselfieadmin' | 'somosselfieproduto';

type PrivateRouteProps = RouteProps & {
  component: React.LazyExoticComponent<React.ComponentType<any>>;
  allowedUserTypes: Array<UserType>;
  mmcodelogin?: boolean;
  selfielogin?: boolean;
  compscilogin?: boolean;
};

const PrivateRoute = ({
  component: Component,
  allowedUserTypes,
  mmcodelogin = false,
  selfielogin = false,
  compscilogin = false,
  ...routeProps
}: PrivateRouteProps) => {
  const profile = useAuth();

  const { isAuthenticated, tipo } = profile;

  const location = useLocation();

  let pathname = '/login';

  if (mmcodelogin) {
    pathname = '/mmcode';
  } else if (selfielogin) {
    pathname = '/selfielogin';
  } else if (compscilogin) {
    pathname = '/compscilogin';
  }

  // useEffect(() => {
  //   const profileValue = JSON.parse(LocalStorage.get('firstcodeUserProfile') || '{}');

  //   if (!!profile?.username && !!profile.accessToken && typeof profileValue.isAuthenticated !== 'boolean') {
  //     LocalStorage.set(
  //       'firstcodeUserProfile',
  //       JSON.stringify({
  //         ...profile,
  //         isAuthenticated: true,
  //       })
  //     );
  //   }
  // }, [profile]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...routeProps}>
      {(!!isAuthenticated || (!!profile?.username && !!profile.accessToken)) &&
      allowedUserTypes.includes(tipo as UserType) ? (
        <Component />
      ) : (
        <Redirect
          to={{
            pathname,
            state: {
              from: location,
              message:
                !tipo || !allowedUserTypes.includes(tipo as UserType)
                  ? 'Usuário não tem permissão na rota'
                  : !isAuthenticated
                  ? 'Usuário não está autenticado'
                  : '',
            },
          }}
        />
      )}
    </Route>
  );
};

export default PrivateRoute;

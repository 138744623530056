import { axiosInstance } from 'lib/axios';

export interface LogSistemaType {
  login: string;
  tipo: string;
  error: string;
  info: string;
}

const LogSistemaService = {
  async createLog(payload: any): Promise<any> {
    const { data } = await axiosInstance.post('LogSistema/salvamentoLog/publico', payload);
    return data;
  },
};

export default LogSistemaService;

import axios, { AxiosResponse } from 'axios';

const axiosInstance = axios.create({
  responseType: 'json',
});

const SecretManagerKeyServices = {
  async existsSchoolChatGPTSecretKey(schoolId: string): Promise<boolean> {
    const { data }: AxiosResponse<boolean> = await axiosInstance.get(
      `/chatgpt/encrypted_key_exists?schoolId=${schoolId}`
    );

    return data;
  },
  async getSchoolChatGPTSecretKey(schoolId: string): Promise<string | null> {
    const { data }: AxiosResponse<string | null> = await axiosInstance.get(
      `/chatgpt/get_encrypted_key?schoolId=${schoolId}`
    );

    return data;
  },
  async setSchoolChatGPTSecretKey(schoolId: string, plainKey: string): Promise<AxiosResponse> {
    const { data } = await axiosInstance.post(`/chatgpt/save_encrypted_key?schoolId=${schoolId}&plainKey=${plainKey}`);

    return data;
  },
  async deleteSchoolChatGPTSecretKey(schoolId: string): Promise<AxiosResponse> {
    const { data } = await axiosInstance.delete(`/chatgpt/delete_encrypted_key?schoolId=${schoolId}`);

    return data;
  },
};

export default SecretManagerKeyServices;

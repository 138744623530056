import React from 'react';

import { AppProvider } from 'contexts/index';
import { AppRoutes } from './routes';

import { Toaster } from 'react-hot-toast';
import PageViewTracker from 'components/PageViewTracker';

function App() {
  return (
    <AppProvider>
      <Toaster
        reverseOrder={false}
        toastOptions={{
          position: 'bottom-right',
          style: {
            borderRadius: '8px',
          },
          success: {
            style: { backgroundColor: '#d1e7dd', color: '#0f5132' },
          },
          error: {
            style: { backgroundColor: '#f35a50', color: '#570000' },
          },
        }}
      />
      {!import.meta.env.DEV && !!import.meta.env.PROD && <PageViewTracker />}
      <AppRoutes />
    </AppProvider>
  );
}

export default App;

/* eslint-disable no-undef */
import React from 'react';
import { createRoot } from 'react-dom/client';

// DEIXAR AQUI - precisa para funcionar global das bibliotecas
if (!window.global) {
  window.global = window;
}

import 'regenerator-runtime/runtime';

import App from './App';

import './i18n';

import './index.css';

window.addEventListener('vite:preloadError', (event) => {
  console.log('preload error', event);

  const reloadedPageKey = `has_reloaded_${window.location.href}`;

  const hasReloaded = sessionStorage.getItem(reloadedPageKey);

  if (!hasReloaded) {
    console.warn('Recarregando...');

    sessionStorage.setItem(reloadedPageKey, 'true');

    window.location.reload();
  }
});

const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

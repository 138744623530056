import React from 'react';

// Para evitar erros Loading chunk x failed
// https://gist.github.com/Botfather/4cfc9c7ba363c892acced7cc44d9f7ff#file-componentloader-js
export const componentLoader = (
  lazyComponent: () => Promise<any>,
  attemptsLeft = 3,
  interval = 1000
): Promise<{
  default: React.ComponentType<any>;
}> => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error: unknown) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

import React from 'react';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'contexts/AuthProvider';
import BigQueryAPI from 'services/external/BigQueryAPI';

export function usePageChange(callback: () => void) {
  const location = useLocation();

  useEffect(() => {
    callback();
  }, [location, callback]);
}

const PageViewTracker = () => {
  const location = useLocation();

  const { username, tipo, escolaId } = useAuth();

  const logPage = async (user_id: string, profile: string, school_id: string) => {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve('Done waiting!');
      }, 5000);
    });

    console.info("Title: ", document.title);
    console.info('Contabilizou ok!');
    
    if (window.location.host === 'mindmakers.cc') {
      await BigQueryAPI.logPageView({
        user_id: user_id,
        profile: profile,
        school_id: school_id,
        page: location.pathname + location.hash,
        title: document.title,
        timestamp: new Date().toISOString(),
      });
    }
  };

  const onRouteChange = () => {
    const user_id = username || 'anonymous';
    const profile = tipo || '';
    const school_id = escolaId || 'noschool';

    const pathname = location.pathname;
    const hash = location.hash;
    const fullPath = pathname + hash;

    const currentPage = fullPath;
    const lastPage = sessionStorage.getItem('lastPage');

    if (lastPage !== currentPage) {
      // Enviar dados de visualização de página para o servidor
      logPage(user_id, profile, school_id);
      sessionStorage.setItem('lastPage', currentPage);
    } else {
      console.info('Page view not counted to avoid duplicate.');
    }
  };

  usePageChange(onRouteChange);

  return null;
};

export default PageViewTracker;

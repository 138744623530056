import { Dispatch, SetStateAction, useState, useCallback, useEffect, useRef } from 'react';

import useEventListener from './useEventListener';

import LocalStorage from 'util/local-storage';

import { parseJSON } from 'util/parseJSON';

declare global {
  interface WindowEventMap {
    'local-storage': CustomEvent;
  }
}

export type SetValue<T> = Dispatch<SetStateAction<T>>;

export type UseLocalStorageReturnType<T> = [T, SetValue<T>];

const useLocalStorage = <T>(key: string, initialValue: T): [T, SetValue<T>] => {
  const readValueFromLocalStorage = useCallback((): T => {
    if (typeof window === 'undefined') return initialValue;

    try {
      const item = LocalStorage.get(key);

      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading '${key}' from localStorage: `, error);

      return initialValue;
    }
  }, [key, initialValue]);

  const [storedValue, setStoredValue] = useState<T>(readValueFromLocalStorage);

  const setValueRef = useRef<SetValue<T>>();

  setValueRef.current = (value: T | ((arg0: T) => T)) => {
    if (typeof window === 'undefined')
      console.warn(`Tried setting localStorage key "${key}" even though environment is not a client`);

    try {
      const newValue = value instanceof Function ? value(storedValue) : value;

      LocalStorage.set(key, JSON.stringify(newValue));

      setStoredValue(newValue);

      // Dispatch custom event so that every useLocalStorage hook are notified
      window.dispatchEvent(new Event('local-storage'));
    } catch (error) {
      console.warn(`Error setting localStorage key "${key}": `, error);
    }
  };

  const setLocalStorageValue: SetValue<T> = useCallback(
    (value: T | ((arg0: T) => T)) => setValueRef.current?.(value),
    []
  );

  useEffect(() => {
    setStoredValue(readValueFromLocalStorage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStorageChange = useCallback(() => {
    setStoredValue(readValueFromLocalStorage());
  }, [readValueFromLocalStorage]);

  useEventListener('storage', handleStorageChange);

  useEventListener('local-storage', handleStorageChange);

  return [storedValue, setLocalStorageValue];
};

export default useLocalStorage;

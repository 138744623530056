import { AxiosPromise, AxiosResponse } from 'axios';

import { axiosInstance } from 'lib/axios';

type BaseConhecimentoIAInsertType = Omit<BaseConhecimentoIADb, 'id'>;

type BaseConhecimentoTopico = Pick<BaseConhecimentoIADb, 'id' | 'baseConhecimento'> & {
  topicos: Array<Pick<Topico, 'topico' | 'sugestoes'>>;
};

const BasesConhecimentoIAService = {
  async list(): Promise<Array<BaseConhecimentoIADb>> {
    const { data }: AxiosResponse<Array<BaseConhecimentoIADb>> = await axiosInstance.get('BasesConhecimentoIA');

    return data;
  },
  async exists(id: string): Promise<boolean> {
    const { data } = await axiosInstance.get(`BasesConhecimentoIA/${id}/exists`);

    return data;
  },
  async findById(id: string): Promise<BaseConhecimentoIADb> {
    const { data } = await axiosInstance.get(`BasesConhecimentoIA/${id}`);

    return data;
  },
  async delete(id: string): Promise<AxiosPromise> {
    const { data } = await axiosInstance.delete(`BasesConhecimentoIA/${id}`);

    return data;
  },
  async insert(payload: BaseConhecimentoIAInsertType): Promise<AxiosPromise> {
    const { data } = await axiosInstance.put('BasesConhecimentoIA/', payload);

    return data;
  },
  async update(payload: BaseConhecimentoIADb): Promise<AxiosPromise> {
    const { data } = await axiosInstance.post('BasesConhecimentoIA', payload);

    return data;
  },
  async recuperarTopicosBases(): Promise<Array<BaseConhecimentoTopico>> {
    const { data }: AxiosResponse<Array<BaseConhecimentoTopico>> = await axiosInstance.get(
      'BasesConhecimentoIA/recuperarTopicosBases'
    );

    return data;
  },
};

export default BasesConhecimentoIAService;
